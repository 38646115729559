/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "3eebcc283bf74685814ac0e604bf2cbe",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "3eebcc283bf74685814ac0e604bf2cbe",
            "region": "us-east-1"
        }
    },
    "aws_cognito_identity_pool_id": "us-east-1:3802d8b9-0a44-4ae9-87c4-89e125553c0a",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_DvZpWfcJP",
    "aws_user_pools_web_client_id": "53uv5t945r1o6p62d7epa2fjp2",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
