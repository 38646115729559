import "@aws-amplify/ui-react/styles.css";
import { Authenticator } from "@aws-amplify/ui-react";
import { Navbar } from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import { Center } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

function Login() {
  const navigate = useNavigate();

  return (
    <Center
      style={{
        marginTop: "15vh",
      }}
    >
      <Helmet>
        <title>Login | Flowmodoro</title>
        <meta
          name="description"
          content="Login to the Flowmodoro app"
        />
      </Helmet>
      <Navbar />
      <Authenticator>
        {({ signOut, user }: any) => {
          navigate("/blocks");
          return <></>;
        }}
      </Authenticator>
    </Center>
  );
}

export default Login;
