import React from "react";
import "./clock.css";
import { Time } from "./Timer";

interface ClockProps {
  time: Time;
}

const FULL_DASH_ARRAY = 534;

const calculateTimeFraction = (secondsRemaining: number, limit: number) => {
  return secondsRemaining / limit;
};

const Clock = ({ time }: ClockProps) => {
  const minutes = Math.floor(time.secondsRemaining / 60);
  const seconds = Math.floor(time.secondsRemaining % 60);
  return (
    <div
      style={{
        textAlign: "center",
      }}
    >
      <div
        style={{
          width: "200px",
          height: "200px",
          position: "relative",
          opacity: 1,
          display: "inline-block"
        }}
      >
        <svg
          className="base_svg"
          viewBox="0 0 180 180"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            style={{
              fill: "none",
              stroke: "none",
            }}
          >
            <circle
              style={{
                strokeWidth: "10",
                stroke: "#eeeeee",
              }}
              cx="90"
              cy="90"
              r="85"
            />
            <path
              id="base-timer-path-remaining"
              className="base-timer_path-remaining"
              strokeDasharray={`${(
                calculateTimeFraction(
                  time.secondsRemaining,
                  60 * time.initialMinutes 
                ) * FULL_DASH_ARRAY
              ).toFixed(0)} 534`}
              color="teal"
              style={{
                strokeWidth: "10",
                strokeLinecap: "round",
                transformOrigin: "center",

                /* One second aligns with the speed of the countdown timer */
                transition: "1s linear all",

                /* Allows the ring to change color when the color value updates */
                stroke: "teal",
              }}
              d="
                      M 90, 90
                      m -85, 0
                      a 85,85 0 1,0 170,0
                      a 85,85 0 1,0 -170,0
                    "
            />
          </g>
        </svg>
        <div
          style={{
            height: "100%",
            width: "100%",
            fontSize: "3rem",
            color: "rgb(0, 90, 88)",
            top: "0px",
            left: "0px",
            position: "absolute",
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1
          }}
        >
          <code>
            {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </code>
        </div>
      </div>
    </div>
  );
};

export default Clock;
