import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useIsLoggedIn } from "./useIsLoggedIn";
import { getBadges } from "../api/badges";

export interface Badge {
  name: string;
  year: number;
  month: number;
  description: string;
}

export const useBadges = () => {
  const [badges, setBadges] = useState<Badge[]>([]);
  const { isLoadingUser, isLoggedIn } = useIsLoggedIn();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  useEffect(() => {
    const badges = async () => {
      const result = await getBadges();
      setBadges(result.data);
      setIsLoading(false);
    };

    if ((!isLoadingUser && isLoggedIn)) {
      badges();
    }
  }, [location.state, isLoadingUser]);

  return {
    badges,
    isLoading,
  };
};
