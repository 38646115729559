import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getWeeklyFlowmodoros } from "../api/weeklyFlowmodoroSchedule";
import { WeeklySchedule } from "../types/types";
import { useIsLoggedIn } from "./useIsLoggedIn";

interface UseSchedulesProps {
    weeklyFlowmodoros : WeeklySchedule[];
    isLoadingWeeklyFlowmodoros : boolean;
    refreshSchedules: Function
}

export const useSchedules = () : UseSchedulesProps => {
  const [weeklyFlowmodoros, setWeeklyFlowmodoros] = useState<WeeklySchedule[]>(
    []
  );
  const { isLoadingUser, isLoggedIn } = useIsLoggedIn();
  const [isLoadingWeeklyFlowmodoros, setIsLoadingWeeklyFlowmodoros] =
    useState(true);
  const location = useLocation();
  useEffect(() => {
    if ((!isLoadingUser && isLoggedIn)) {
      refreshSchedules();
    }
  }, [location.state, isLoadingUser]);

  const refreshSchedules = async () => {
    const result = await getWeeklyFlowmodoros();
    setWeeklyFlowmodoros(result.data);
    setIsLoadingWeeklyFlowmodoros(false);
  };

  return {
    refreshSchedules,
    weeklyFlowmodoros,
    isLoadingWeeklyFlowmodoros,
  };
};
