import React from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Center,
  Container,
  Heading,
  SimpleGrid,
  Text,
  Image,
} from "@chakra-ui/react";

const Features = () => {
  return (
    <section
      style={{
        backgroundColor: "white",
        color: "teal",
        paddingBottom: "10vh",
        textAlign: "center",
      }}
    >
      <Center>
        <Container>
          <Heading
            style={{
              margin: "10px",
            }}
            size="xl"
          >
            Features
          </Heading>
          <SimpleGrid
            spacing={4}
            templateColumns="repeat(auto-fill, minmax(200px, 1fr))"
          >
            <Card>
              <CardHeader>
                <Heading size="md">Blocks</Heading>
              </CardHeader>
              <Center>
                <Image
                  objectFit="cover"
                  maxW={{ base: "100%", sm: "200px" }}
                  maxH={{ base: "100%", sm: "200px" }}
                  src="/screenshots/Blocks.png"
                  alt="Blocks"
                />
              </Center>
              <CardBody>
                <Text>
                  Block time during the week to spend time on tasks you care
                  about.
                </Text>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <Heading size="md">Timer</Heading>
              </CardHeader>
              <Center>
                <Image
                  maxW={{ base: "100%", sm: "200px" }}
                  maxH={{ base: "100%", sm: "200px" }}
                  src="/screenshots/Flowmodoro.png"
                  alt="Timer"
                />
              </Center>
              <CardBody>
                <Text>Record the time you spend on each Block.</Text>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <Heading size="md">Badges</Heading>
              </CardHeader>
              <Center>
                <Image
                  objectFit="cover"
                  maxW={{ base: "100%", sm: "200px" }}
                  maxH={{ base: "100%", sm: "200px" }}
                  src="/screenshots/Badges.png"
                  alt="Badges"
                />
              </Center>
              <CardBody>
                <Text>
                  Retrieve badges for spending time on the things you care
                  about.
                </Text>
              </CardBody>
            </Card>

            <Card
              style={{
                textAlign: "center",
              }}
            >
              <CardHeader>
                <Heading size="md">Statistics</Heading>
              </CardHeader>
              <Center>
                <Image
                  objectFit="cover"
                  maxW={{ base: "100%", sm: "200px" }}
                  maxH={{ base: "100%", sm: "200px" }}
                  src="/screenshots/Statistics.png"
                  alt="Statistics"
                />
              </Center>
              <CardBody>
                <Text>
                  Keep track of how much time you spend on each Block.
                </Text>
              </CardBody>
            </Card>
          </SimpleGrid>
        </Container>
      </Center>
    </section>
  );
};

export default Features;
