import axios, { AxiosResponse } from "axios";
import { API_URL, getAxiosConfig } from "./common";
import { TYPES, WeeklySchedule } from "../types/types";

export const getWeeklyFlowmodoros = async () : Promise<AxiosResponse<WeeklySchedule[]>> => {
  const weeklyFlowmodoros = await axios.get(`${API_URL}/schedules`, await getAxiosConfig());
  return weeklyFlowmodoros;
};

export const postWeeklyFlowmodoro = async (weeklySchedule: WeeklySchedule) => {
  const result = await axios.post(
    `${API_URL}/schedule`,
    { ...weeklySchedule, type: TYPES.SCHEDULE },
    await getAxiosConfig()
  );
  return result;
};

export const putWeeklyFlowmodoro = async (weeklySchedule: WeeklySchedule) => {
  const result = await axios.put(
    `${API_URL}/schedule`,
    { ...weeklySchedule, type: TYPES.SCHEDULE },
    await getAxiosConfig()
  );
  return result;
};

export const deleteWeeklyFlowmodoro = async (name: string) => {
    const result = await axios.delete(
        `${API_URL}/schedule/${name}`,
        await getAxiosConfig()
      );
      return result;
}
