import { HamburgerIcon } from "@chakra-ui/icons";
import { Heading, IconButton, Menu, MenuButton } from "@chakra-ui/react";
import React from "react";
import { FlowmodoroMenuList } from "./FlowmodoroMenuList";

export const Navbar = () => {
  return (
    <div
      style={{
        position: "fixed" /* Set the navbar to fixed position */,
        top: 0 /* Position the navbar at the top of the page */,
        width: "100%" /* Full width */,
        backgroundColor: "teal",
        zIndex: 9999,
      }}
    >
      <div
        onClick={() => window.location.replace("/")}
        style={{
          float: "left",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          cursor: "pointer",
          padding: "14px 16px",
        }}
      >
        <Heading as="h1" size="md">
          Flowmodoro
        </Heading>
      </div>
      <div
        style={{
          float: "right",
          display: "block",
          padding: "7px",
        }}
      >
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<HamburgerIcon />}
            variant="outline"
          />
          <FlowmodoroMenuList />
        </Menu>
      </div>
    </div>
  );
};
