import {
  Button,
  Center,
  Heading,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Analytics } from "aws-amplify";
import { Navbar } from "../../components/Navbar";
import { getEmail } from "../../hooks/useIsLoggedIn";
import { Helmet } from "react-helmet";

const feedbackOnClick = (text: string, email: string) => {
  Analytics.record({
    name: "feedback",
    immediate: true,
    attributes: {
      text,
      email,
    },
  });
};

const Feedback = () => {
  const [textAreaInput, setTextAreaInput] = useState("");
  const [email, setEmail] = useState("");
  const toast = useToast();

  useEffect(() => {
    getEmail().then((emailResult) => {
      setEmail(emailResult);
    });
  }, []);
  return (
    <Center
      style={{
        marginTop: "20vh",
      }}
    >
      <Navbar />
      <Helmet>
        <title>Feedback | Flowmodoro</title>
        <meta
          name="description"
          content="Flowmodoro Feedback"
        />
      </Helmet>
      <div
        style={{
          textAlign: "center",
        }}
      >
        <Heading style={{
            margin: "10px"
        }}>Leave Feedback</Heading>
        <div style={{
            margin: "10px"
        }}>
          Thank you so much for trying out Flowmodoro! I'd love to hear your
          feedback - positive or negative - about the app.
        </div>
        <Textarea
          value={textAreaInput}
          onChange={(event) => {
            if (event.target.value.length < 200) {
                setTextAreaInput(event.target.value)
            }
        }}
        />
        <Button
            style={{
                margin: "10px"
            }}
          onClick={() => {
            if (textAreaInput.length > 0) {
              feedbackOnClick(textAreaInput, email);
              setTextAreaInput("");
              toast({
                title: "Feedback sent",
                description: "Thank you for supporting Flowmodoro!",
                status: "success",
                duration: 5000,
                isClosable: true,
              });
            }
          }}
        >
          Submit
        </Button>
      </div>
    </Center>
  );
};

export default Feedback;
