import { Container } from '@chakra-ui/react'
import React from 'react'

const UseCases = () => {
  return (
    <section
      style={{
        backgroundColor: "white",
        color: "teal",
        paddingTop: "3rem",
        paddingBottom: "5rem",
      }}
    >
      <Container>
        <h2
          style={{
            textAlign: "center",
            fontSize: "2rem",
            fontWeight: 500,
          }}
        >
          Use Cases
        </h2>

        

        <br />
        <h3
          style={{
            fontSize: "1.5rem",
            fontWeight: 500,
          }}
        >
          Make daily progress toward goals
        </h3>
        <p
          style={{
            fontSize: "1.125rem",
          }}
        >
          With Flowmodoro, you set aside how much time you want to spend for each day of the week through Blocks.
        </p>
        <br />
        <h3
          style={{
            fontSize: "1.5rem",
            fontWeight: 500,
          }}
        >
          Track your time
        </h3>
        <p
          style={{
            fontSize: "1.125rem",
          }}
        >
          With our built in timer and the ability to see how much time you spend over weeks, months, and years on your tasks, you can hold yourself accountable to the time you spend on your work.
        </p>

        <br />
        <h3
          style={{
            fontSize: "1.5rem",
            fontWeight: 500,
          }}
        >
          Beat procrastination
        </h3>
        <p
          style={{
            fontSize: "1.125rem",
          }}
        >
          Rather than put things off till the last minute, Flowmodoro helps you treat your work like something you show up to every day to ensure you make progress.
        </p>
        <br />
      </Container>
    </section>
  )
}

export default UseCases