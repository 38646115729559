import React from "react";
import {
  Box,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Text,
  Card,
} from "@chakra-ui/react";
import { Time } from "./Timer";
import { DragHandleIcon } from "@chakra-ui/icons";

interface SetMinutesScreenProps {
  setTime: Function;
  time: Time;
}

const SetMinutesScreen = ({
  setTime,
  time
}: SetMinutesScreenProps) => {
  return (
    <Card
      padding={"3"}
      align="center"
      style={{
        textAlign: "center",
        width: "220px",
        marginBottom: "1rem",
      }}
    >
      {/* <CircularSlider
        width={220}
        knobColor="#005a58"
        labelColor="#005a58"
        min={1}
        max={60}
        dataIndex={sliderInitialMinutes}
        label="Set Minutes"
        trackColor="#eeeeee"
        progressColorFrom="teal"
        progressColorTo="teal"
        trackSize={16}
        progressSize={16}
        knobSize={60}
        onChange={(value : any) => {
          setTime({
          ...time,
          initialMinutes: value,
          secondsRemaining: value * 60
        })}}
      /> */}


      <Text fontSize="md"><strong>Check in after</strong></Text>
        <Text fontSize="5xl">{time.initialMinutes}</Text>
        <Text fontSize="md"><strong>minutes</strong></Text>
        <Slider
          id="slider"
          size="lg"
          defaultValue={time.initialMinutes}
          min={1}
          max={60}
          colorScheme="teal"
          onChange={(value) =>
            setTime({
              ...time,
              initialMinutes: value,
              secondsRemaining: value * 60,
            })
          }
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>

            <SliderThumb boxSize="8" borderColor="grey">
              <Box color="teal" as={DragHandleIcon}/>
            </SliderThumb>

        </Slider>
    </Card>
  );
};

export default SetMinutesScreen;
