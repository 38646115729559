import React, { useEffect } from "react";
import HomeIntro from "./sections/HomeIntro";
import { Navbar } from "../../components/Navbar";
import HowItWorks from "./sections/HowItWorks";
import Features from "./sections/Features";
import GetStarted from "./sections/GetStarted";
import UnlockYourPotential from "./sections/UnlockYourPotential";
import UseCases from "./sections/UseCases";
import { useIsLoggedIn } from "../../hooks/useIsLoggedIn";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const {isLoggedIn, isLoadingUser} = useIsLoggedIn();
  const navigate = useNavigate();
  useEffect(() => {
    if (isLoggedIn) {
      navigate("/blocks")
    }
  }, [isLoggedIn]);
  if (isLoadingUser || isLoggedIn) {
    return <></>;
  }
  return (
    <div>
      <Navbar />
      <HomeIntro />
      <UnlockYourPotential />
      <UseCases />
      <HowItWorks />
      <Features />
      <GetStarted />
    </div>
  );
};

export default Home;
