import React from "react";
import {
  MenuList,
  MenuItem,
  Text,
  HStack,
  MenuGroup,
  MenuDivider,
} from "@chakra-ui/react";
import { useIsLoggedIn } from "../hooks/useIsLoggedIn";
import { signOut } from "../auth/auth";
import { Link } from "react-router-dom";


export const FlowmodoroMenuList = () => {
  const { isLoggedIn, isLoadingUser } = useIsLoggedIn();
  return (
    <MenuList>
      {isLoadingUser || !isLoggedIn ? (
        <>
          <Link to="/login">
            <MenuItem>
              <HStack>
                <Text>Login</Text>
              </HStack>
            </MenuItem>
          </Link>
          <Link to="/guides">
            <MenuItem>
              <HStack>
                <div>Guides</div>
              </HStack>
            </MenuItem>
          </Link>
          <Link to="/blog">
            <MenuItem>
              <HStack>
                <Text>Blog</Text>
              </HStack>
            </MenuItem>
          </Link>
        </>
      ) : (
        <>
        <MenuGroup title="My Stuff">
          <Link to="/blocks">
            <MenuItem>
              <HStack>
                <Text>Blocks</Text>
              </HStack>
            </MenuItem>
          </Link>
          <Link to="/badges">
            <MenuItem>
              <HStack>
                <Text>Badges</Text>
              </HStack>
            </MenuItem>
          </Link>
          
          <Link to="/stats">
            <MenuItem>
              <HStack>
                <Text>Statistics</Text>
              </HStack>
            </MenuItem>
          </Link>
          <Link to="/records">
            <MenuItem>
              <HStack>
                <Text>Records</Text>
              </HStack>
            </MenuItem>
          </Link>
          </MenuGroup>
          <MenuDivider />
          <MenuGroup title="Help">
          <Link to="/guides">
            <MenuItem>
              <HStack>
                <Text>Guides</Text>
              </HStack>
            </MenuItem>
          </Link>
          <Link to="/blog">
            <MenuItem>
              <HStack>
                <Text>Blog</Text>
              </HStack>
            </MenuItem>
          </Link>
          <Link to="/feedback">
            <MenuItem>
              <HStack>
                <Text>Feedback</Text>
              </HStack>
            </MenuItem>
          </Link>
          </MenuGroup>
          
          <MenuDivider />
          <MenuGroup title="Profile">
            <Link to="/preferences">
              <MenuItem>
                <HStack>
                  <Text>Preferences</Text>
                </HStack>
              </MenuItem>
            </Link>
            <MenuItem
              onClick={() => signOut().then(() => window.location.replace("/"))}
            >
              <HStack>
                <Text>Log Out</Text>
              </HStack>
            </MenuItem>
          </MenuGroup>
        </>
      )}
    </MenuList>
  );
};
