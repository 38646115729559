import React from "react";
import { Flowmodoro } from "../../../types/types";
import { getYearFlowmodoros } from "../utils/statYearUtils";
import { Heading, Stat, StatGroup, StatHelpText, StatLabel, StatNumber } from "@chakra-ui/react";
import { getTotalMinutes } from "../utils/statsUtils";
import { ResponsiveBar } from "@nivo/bar";

interface YearTabProps {
  records: Flowmodoro[];
}

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

const mapFlowmodoroTotals = (records: Flowmodoro[]) => {
    const initialData = months.map((month) => ({
      month,
      minutes: 0,
      total: 0,
    }));
  
    for (let i = 0; i < records.length; i++) {
      const dayOfWeek = new Date(records[i].date).getMonth();
      initialData[dayOfWeek] = {
        ...initialData[dayOfWeek],
        minutes: initialData[dayOfWeek].minutes + records[i].minutes,
        total: initialData[dayOfWeek].total + 1,
      };
    }
    return initialData;
  };

const currentYear = () => new Date().getFullYear()

const YearTab = ({ records }: YearTabProps) => {
  const yearRecords = getYearFlowmodoros(records, currentYear());
  const totalMinutes = getTotalMinutes(yearRecords);
  const days = Math.floor(totalMinutes / (60 * 24)).toFixed(0);
  const leftoverMinutes = totalMinutes % (60 * 24);
  const hours = Math.floor(leftoverMinutes / 60).toFixed(0);
  const minutes = Math.floor(leftoverMinutes % 60).toFixed(0);
  const flowmodoroTotals = mapFlowmodoroTotals(yearRecords);
  return (
    <div>
      <Heading>Time</Heading>
      <StatGroup>
            <Stat>
              <StatNumber>{days}</StatNumber>
              <StatHelpText>Days</StatHelpText>
            </Stat>
            <Stat>
              <StatNumber>{hours}</StatNumber>
              <StatHelpText>Hours</StatHelpText>
            </Stat>
            <Stat>
              <StatNumber>{minutes}</StatNumber>
              <StatHelpText>Minutes</StatHelpText>
            </Stat>
          </StatGroup>
      <div
        style={{
          height: "30vh",
        }}
      >
        <ResponsiveBar
          data={flowmodoroTotals}
          colors={['teal']}
          labelTextColor={"white"}
          indexBy={"month"}
          keys={["minutes"]}
          padding={0.3}
          role="application"
          margin={{ bottom: 50, left: 30, top: 50 }}
          ariaLabel="Nivo bar chart demo"
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: 'middle',
            legendOffset: -40,
            truncateTickAt: 0
        }}
        />
      </div>

      <Heading>Quantity</Heading>
      <Stat>
        <StatLabel>Completed</StatLabel>
        <StatNumber>{yearRecords.length}</StatNumber>
        <StatHelpText>Flowmodoros</StatHelpText>
      </Stat>

      <div
        style={{
          height: "30vh",
        }}
      >
        <ResponsiveBar
          data={flowmodoroTotals}
          labelTextColor={"white"}
          colors={['teal']}
          indexBy={"month"}
          keys={["total"]}
          padding={0.3}
          margin={{ bottom: 50, top: 50, left: 30 }}
          role="application"
        />
      </div>
    </div>
  );
};

export default YearTab;
