import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";

export const getEmail = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user.attributes.email;
  } catch {
    return null;
  }
}

const isUserLoggedIn = async () => {
    try {
      const result = await Auth.currentAuthenticatedUser();
      return true;
    } catch {
      return false;
    }
  };

export const useIsLoggedIn = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoadingUser, setIsLoadingUser] = useState(true);
    useEffect(() => {
        const checkLogin = async () => {
            const result = await isUserLoggedIn();
            if (result) {
                setIsLoggedIn(true);
            }
            setIsLoadingUser(false);
                
        }
        checkLogin();
    }, [])

    return {
        isLoadingUser,
        isLoggedIn
    }
}