import {
  Card,
  CardBody,
  Heading,
  Spinner,
  Button,
  Center,
  Flex,
  Spacer,
  Text,
  Menu,
  MenuButton,
  MenuList,
  IconButton,
  MenuItem,
  Icon,
} from "@chakra-ui/react";
import React, { useState } from "react";
import {
  getMinutesSpentToday,
  getRecordsWithScheduleName,
  hasCompletedToday,
} from "./SchedulesUtils";
import { Flowmodoro, WeeklySchedule } from "../../types/types";
import { useNavigate } from "react-router-dom";
import { getDueFlowmodoro, getTimeString } from "../../utils/time";
import DeleteScheduleModal from "../../components/DeleteScheduleModal";
import { getCurrentStreak } from "../Stats/utils/statsUtils";

interface BlockCardProps {
  weeklyFlowmodoro: WeeklySchedule;
  isLoading: Boolean;
  records: Flowmodoro[];
  isCompleted: boolean;
  refreshSchedules: Function;
}

const BlockCard = ({
  weeklyFlowmodoro,
  records,
  isLoading,
  isCompleted,
  refreshSchedules,
}: BlockCardProps) => {
  const navigate = useNavigate();
  const [isDeleteScheduleModalOpen, setIsDeleteScheduleModalOpen] =
    useState(false);

  const recordsWithScheduleName = getRecordsWithScheduleName(
    records,
    weeklyFlowmodoro
  );

  return (
    <Card
      maxW="400px"
      minW="300px"
      style={{
        backgroundColor: isCompleted ? "lightseagreen" : "#FFFFFF",
      }}
    >
      <DeleteScheduleModal
        name={weeklyFlowmodoro.name}
        isOpen={isDeleteScheduleModalOpen}
        setIsOpen={setIsDeleteScheduleModalOpen}
        refreshSchedules={refreshSchedules}
      />
      {!isLoading ? (
        <>
          <div
            style={{
              margin: ".5rem",
            }}
          >
            <Flex justifyContent={"center"} alignItems="center">
              <Heading size="sm" colorScheme="teal">
                {weeklyFlowmodoro.name}
              </Heading>
              <Spacer />
              <Menu>
                <MenuButton
                  backgroundColor="transparent"
                  size="sm"
                  as={IconButton}
                  aria-label="Options"
                  icon={<img style={{
                    width: "1.5rem",
                    height: "1.5rem"
                  }}src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAAXNSR0IArs4c6QAAALdJREFUWEftlDEKwkAURF/uEBC8okWwExEbCRYBQTxkAt7ASgMiYQnOL74QZLZZ2BmW2feHrVjYqhaWBwdSEzEhE1IElO4OmZAioHR36K8IjeM8AJv3q25AW7wwy/O59luHLsC2CHACjpOzLE8o0B2oi0A9sJ6cZXlCgQZgJQJleUKBzsBOjCzLEwo09msPNK/9AVyBbqbUGZ5QIPVl/ET3T62wmpAJKQJKd4dMSBFQujtkQoqA0p+I9CclYZtiDAAAAABJRU5ErkJggg=="/>}
                />
                <MenuList>
                  <MenuItem
                    onClick={() =>
                      navigate(`/blocks/edit/${weeklyFlowmodoro.name}`)
                    }
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    onClick={() => setIsDeleteScheduleModalOpen(true)}
                  >
                    Delete
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
            <Flex
              pt=".2rem"
              style={{
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <div>
                {getCurrentStreak(recordsWithScheduleName) >= 1 && (
                  <Text fontSize="sm">
                    &#128293;
                    {getCurrentStreak(recordsWithScheduleName)} Day(s)
                  </Text>
                )}
                <Text fontSize="sm">
                  <strong>Completed:</strong>{" "}
                  {getMinutesSpentToday(new Date(), recordsWithScheduleName)}/
                  {getDueFlowmodoro(new Date(), weeklyFlowmodoro)} minutes
                </Text>
              </div>
              <Spacer />
              <Button
                size="sm"
                colorScheme="teal"
                variant="solid"
                padding={"1rem"}
                onClick={() => {
                  const minutesSpent = getMinutesSpentToday(
                    new Date(),
                    recordsWithScheduleName
                  );
                  navigate("/timer", {
                    state: {
                      name: weeklyFlowmodoro.name,
                      minutesSpent,
                      minutesDue: getDueFlowmodoro(
                        new Date(),
                        weeklyFlowmodoro
                      ),
                    },
                  });
                }}
              >
                <Text>Start</Text>
              </Button>
            </Flex>
          </div>
        </>
      ) : (
        <Center>
          <Spinner />
        </Center>
      )}
    </Card>
  );
};

export default BlockCard;
