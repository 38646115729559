import {
  Card,
  CardHeader,
  Heading,
  CardBody,
  Flex,
  Box,
  Divider,
  Text,

} from "@chakra-ui/react";
import React from "react";
import { Time } from "./Timer";

interface SessionStatsProps {
  time: Time;
  minutesDue: number;
  minutesSpent: number;
}

const SessionStats = ({
  time,
  minutesDue,
  minutesSpent,
}: SessionStatsProps) => {
  return (
    <Card size="sm">
      <CardBody>
        <Flex
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          {/* <Center mr="3">
              <CircularProgress
                value={
                  minutesDue === 0 || (time.totalSessionTime + minutesSpent) > minutesDue
                    ? 100
                    : ((time.totalSessionTime + minutesSpent) / minutesDue) * 100
                }
                color="teal.400"
              >
                <CircularProgressLabel>
                  {minutesDue === 0 || (time.totalSessionTime + minutesSpent) > minutesDue
                    ? 100
                    : (
                        ((time.totalSessionTime + minutesSpent) / minutesDue) *
                        100
                      ).toFixed(0)}
                  %
                </CircularProgressLabel>
              </CircularProgress>
            </Center> */}
          <Box>
          <Text as="b" fontSize="sm">Flowmodoro Stats</Text>
            <Text fontSize="sm"><strong>{time.totalSessionTime}</strong></Text>
            <Text fontSize="xs">Minutes Completed</Text>
            <Divider m="1"/>
            <Text fontSize="xs">Completed today: {time.totalSessionTime + minutesSpent} minute(s)</Text>
            <Text fontSize="xs">Due today: {minutesDue} minute(s)</Text>
          </Box>
            
        </Flex>
      </CardBody>
    </Card>
  );
};

export default SessionStats;
