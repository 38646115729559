export interface Flowmodoro {
	date: number; //the date assigned to this
	scheduleName : string;
	minutes : number; // the limit
	description?: string;
}

export interface UpdateWeeklySchedule extends WeeklySchedule {
	oldName: string;
}

export interface WeeklySchedule {
    name: string;
	mondayTime : number;
	tuesdayTime: number;
	wednesdayTime: number;
	thursdayTime: number;
	fridayTime: number;
	saturdayTime: number;
	sundayTime: number;
}


export const TYPES = {
	SCHEDULE : "SCHEDULE",
	RECORD : "RECORD",
	PREFERENCES : "PREFERENCES"
}

