import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';


import { Amplify, Analytics } from 'aws-amplify';
import config from './aws-exports';
import App from './App';
import { trackAnalytics } from './analytics/configure';
trackAnalytics(Analytics);
Amplify.configure(config);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ChakraProvider>
      <App />
    </ChakraProvider>
  </React.StrictMode>
);
