import React from 'react'
import { Flowmodoro } from '../../../types/types'
import { getTotalMinutes } from '../utils/statsUtils';
import { Heading, StatGroup, Stat, StatNumber, StatHelpText } from '@chakra-ui/react';
import { ResponsiveBar } from '@nivo/bar';

interface AllTabProps {
    records: Flowmodoro[]
}

const years = ['2024'];

const mapFlowmodoroTotals = (records: Flowmodoro[]) => {
  const initialData = years.map((year) => ({
    year,
    minutes: 0,
    total: 0,
  }));

  for (let i = 0; i < records.length; i++) {
    const year = new Date(records[i].date).getFullYear().toString();
    if (!years.includes(year)) {
      continue;
    }
    const yearIndex = years.indexOf(year);
    initialData[yearIndex] = {
      ...initialData[yearIndex],
      minutes: initialData[yearIndex].minutes + records[i].minutes,
      total: initialData[yearIndex].total + 1,
    };
  }
  return initialData;
};


const AllTab = ({ records } : AllTabProps) => {
  const totalMinutes = getTotalMinutes(records);
  const days = Math.floor(totalMinutes / (60 * 24)).toFixed(0);
  const leftoverMinutes = totalMinutes % (60 * 24);
  const hours = Math.floor(leftoverMinutes / 60).toFixed(0);
  const minutes = Math.floor(leftoverMinutes % 60).toFixed(0);
  const flowmodoroTotals = mapFlowmodoroTotals(records);
  return (
    <div style={{
        
    }}>
          <Heading>Time</Heading>
          <StatGroup>
            <Stat>
              <StatNumber>{days}</StatNumber>
              <StatHelpText>Days</StatHelpText>
            </Stat>
            <Stat>
              <StatNumber>{hours}</StatNumber>
              <StatHelpText>Hours</StatHelpText>
            </Stat>
            <Stat>
              <StatNumber>{minutes}</StatNumber>
              <StatHelpText>Minutes</StatHelpText>
            </Stat>
          </StatGroup>
          <div
        style={{
          height: "40vh",
        }}
      >
        <ResponsiveBar
          colors={['teal']}
          data={flowmodoroTotals}
          indexBy={"year"}
          labelTextColor={"white"}
          keys={["minutes"]}
          padding={0.3}
          role="application"
          margin={{ bottom: 50, left: 30, top: 50 }}
          ariaLabel="Nivo bar chart demo"
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: 'middle',
            legendOffset: -40,
            truncateTickAt: 0
        }}
        />
      </div>
          <Heading>Quantity</Heading>
          <Stat>
              <StatNumber>{records.length}</StatNumber>
              <StatHelpText>Flowmodoros</StatHelpText>
            </Stat>
            <div
        style={{
          height: "40vh",
        }}
      >
        <ResponsiveBar
          colors={['teal']}
          data={flowmodoroTotals}
          indexBy={"year"}
          labelTextColor={"white"}
          keys={["total"]}
          padding={0.3}
          role="application"
          margin={{ bottom: 50, left: 30, top: 50 }}
          ariaLabel="Nivo bar chart demo"
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: 'middle',
            legendOffset: -40,
            truncateTickAt: 0
        }}
        />
      </div>
        </div>
  )
}

export default AllTab