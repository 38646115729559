import { Flowmodoro, WeeklySchedule } from "../../types/types";

export const hasCompletedToday = (
  weeklySchedule: WeeklySchedule,
  records: Flowmodoro[],
  todaysDate: Date
) => {
  const minutesDue = getMinutesDue(todaysDate, weeklySchedule);
  if (minutesDue === 0) {
    return true;
  }
  const minutesSpentToday = getMinutesSpentToday(todaysDate, records);

  if (minutesSpentToday >= minutesDue) {
    return true;
  }

  return false;
};

const getMinutesDue = (todaysDate: Date, weeklySchedule: WeeklySchedule) => {
  switch (todaysDate.getDay()) {
    case 0:
      return weeklySchedule.sundayTime;
    case 1:
      return weeklySchedule.mondayTime;
    case 2:
      return weeklySchedule.tuesdayTime;
    case 3:
      return weeklySchedule.wednesdayTime;
    case 4:
      return weeklySchedule.thursdayTime;
    case 5:
      return weeklySchedule.fridayTime;
    case 6:
      return weeklySchedule.saturdayTime;
  }
  return 0;
};

export const getMinutesSpentToday = (
  todaysDate: Date,
  recordsWithScheduleName: Flowmodoro[]
) => {
  let minutesSpentToday = 0;
  for (let i = 0; i < recordsWithScheduleName.length; i++) {
    const recordDate = new Date(recordsWithScheduleName[i].date);
    if (
      recordDate.getDate() == todaysDate.getDate() &&
      recordDate.getMonth() === todaysDate.getMonth() &&
      recordDate.getFullYear() === todaysDate.getFullYear()
    ) {
      minutesSpentToday += recordsWithScheduleName[i].minutes;
    }
  }
  return minutesSpentToday;
};

export const getRecordsWithScheduleName = (
  records: Flowmodoro[],
  weeklySchedule: WeeklySchedule
) =>
  records.filter(
    (record: Flowmodoro) => record.scheduleName === weeklySchedule.name
  );
