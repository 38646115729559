import { Center, Heading } from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import React from "react";
import { Flowmodoro } from "../../types/types";
import WeekTab from "./Tabs/WeekTab";
import YearTab from "./Tabs/YearTab";
import AllTab from "./Tabs/AllTab";

interface StatsDetailsProps {
  records: Flowmodoro[];
}

const StatsDetails = ({ records }: StatsDetailsProps) => {
  return (
    <Center>
      <div>
        <div>
          <Tabs align="center">
            <TabList>
              <Tab>Week</Tab>
              <Tab>Year</Tab>
              <Tab>Totals</Tab>
            </TabList>
            <TabPanels>
              <TabPanel
                style={{
                  width: "90vw",
                }}
              >
                <WeekTab records={records} />
              </TabPanel>
              <TabPanel
                style={{
                  width: "90vw",
                }}
              >
                <YearTab records={records} />
              </TabPanel>
              <TabPanel
                style={{
                  width: "90vw",
                }}
              >
                <AllTab records={records} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </div>
      </div>
    </Center>
  );
};

export default StatsDetails;
