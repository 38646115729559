import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Heading,
  Spinner,
  Box,
  Center,
  Button,
  InputGroup,
  InputLeftAddon,
  SimpleGrid,
} from "@chakra-ui/react";
import {
  getWeeklyFlowmodoros,
  putWeeklyFlowmodoro,
} from "../../api/weeklyFlowmodoroSchedule";
import { UpdateWeeklySchedule, WeeklySchedule } from "../../types/types";
import { useNavigate, useParams } from "react-router-dom";
import { useIsLoggedIn } from "../../hooks/useIsLoggedIn";
import { Navbar } from "../../components/Navbar";
import { minutes } from "../../common/minutes";
import { Helmet } from "react-helmet";

const handleSubmit = async (
  weeklySchedule: UpdateWeeklySchedule,
  navigate: Function
) => {
  await putWeeklyFlowmodoro(weeklySchedule).catch((e) =>
    console.log("AN ERROR OCCURED")
  );
  navigate("/blocks");
};

const getSchedule = async (
  scheduleName: string
): Promise<WeeklySchedule | null> => {
  const response = await getWeeklyFlowmodoros();
  const weeklyFlowmodoros = response.data;
  for (let i = 0; i < weeklyFlowmodoros.length; i++) {
    if (weeklyFlowmodoros[i].name === scheduleName) {
      return weeklyFlowmodoros[i];
    }
  }

  return null;
};

const EditSchedule = () => {
  const { scheduleName } = useParams();
  const [name, setName] = useState('');
  const [mondayTime, setMondayTime] = useState(minutes.FIFTEEN);
  const [tuesdayTime, setTuesdayTime] = useState(minutes.FIFTEEN);
  const [wednesdayTime, setWednesdayTime] = useState(minutes.FIFTEEN);
  const [thursdayTime, setThursdayTime] = useState(minutes.FIFTEEN);
  const [fridayTime, setFridayTime] = useState(minutes.FIFTEEN);
  const [saturdayTime, setSaturdayTime] = useState(minutes.FIFTEEN);
  const [sundayTime, setSundayTime] = useState(minutes.FIFTEEN);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoadingFlowmodoro, setIsLoadingFlowmodoro] = useState(true);

  const navigate = useNavigate();

  const { isLoadingUser, isLoggedIn } = useIsLoggedIn();

  useEffect(() => {
    const updateSchedule = async () => {
      const matchingSchedule = await getSchedule(scheduleName!);
      if (matchingSchedule === null) {
        navigate("/blocks");
      } else {
        setName(scheduleName!);
        setMondayTime(matchingSchedule.mondayTime);
        setTuesdayTime(matchingSchedule.tuesdayTime);
        setWednesdayTime(matchingSchedule.wednesdayTime);
        setThursdayTime(matchingSchedule.thursdayTime);
        setFridayTime(matchingSchedule.fridayTime);
        setSaturdayTime(matchingSchedule.saturdayTime);
        setSundayTime(matchingSchedule.sundayTime);
      }
      setIsLoadingFlowmodoro(false);
    };
    updateSchedule();
  }, []);

  if (scheduleName === undefined) {
    navigate("/blocks");
  }

  return (
    <Center
      style={{
        marginTop: "10vh",
      }}
    >
      <Navbar />
      <Helmet>
        <title>Edit Block | Flowmodoro</title>
        <meta
          name="description"
          content="Edit the number of minutes per day in a block."
        />
      </Helmet>

      {isLoadingUser || isLoadingFlowmodoro ? (
        <Spinner />
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setIsSubmitting(true);
            handleSubmit(
              {
                oldName: scheduleName!,
                name,
                mondayTime,
                tuesdayTime,
                wednesdayTime,
                thursdayTime,
                fridayTime,
                saturdayTime,
                sundayTime,
              },
              navigate
            );
            setIsSubmitting(false);
          }}
        >
          <FormControl
            isRequired
            style={{
              textAlign: "center",
            }}
          >
            <Heading
              style={{
                textAlign: "center",
              }}
            >
              Edit Block
            </Heading>
            <Box>
              <FormLabel textAlign={"center"}>Name</FormLabel>
              <Input
                onChange={(e) => setName(e.target.value)}
                type="text"
                aria-label="Name"
                value={name}
              />
            </Box>
            <FormLabel textAlign={"center"}>Minutes per day</FormLabel>
            <Center>
              <SimpleGrid columns={2} spacing={3}>
                <InputGroup>
                  <InputLeftAddon width="110px" children="Mon." />
                  <Input
                    type="number"
                    maxW={20}
                    defaultValue={minutes.FIFTEEN}
                    min={0}
                    max={300}
                    value={mondayTime}
                    onChange={(e) => setMondayTime(parseInt(e.target.value))}
                  />
                </InputGroup>
                <InputGroup>
                  <InputLeftAddon width="110px" children="Tue." />
                  <Input
                    type="number"
                    maxW={20}
                    defaultValue={minutes.FIFTEEN}
                    min={0}
                    max={300}
                    value={tuesdayTime}
                    onChange={(e) => setTuesdayTime(parseInt(e.target.value))}
                  />
                </InputGroup>
                <InputGroup>
                  <InputLeftAddon width="110px" children="Wed." />
                  <Input
                    type="number"
                    maxW={20}
                    defaultValue={minutes.FIFTEEN}
                    min={0}
                    max={300}
                    value={wednesdayTime}
                    onChange={(e) => setWednesdayTime(parseInt(e.target.value))}
                  />
                </InputGroup>
                <InputGroup>
                  <InputLeftAddon width="110px" children="Thu." />
                  <Input
                    type="number"
                    maxW={20}
                    defaultValue={minutes.FIFTEEN}
                    min={0}
                    max={300}
                    value={thursdayTime}
                    onChange={(e) => setThursdayTime(parseInt(e.target.value))}
                  />
                </InputGroup>
                <InputGroup>
                  <InputLeftAddon width="110px" children="Fri." />
                  <Input
                    type="number"
                    maxW={20}
                    defaultValue={minutes.FIFTEEN}
                    min={0}
                    max={300}
                    value={fridayTime}
                    onChange={(e) => setFridayTime(parseInt(e.target.value))}
                  />
                </InputGroup>
                <InputGroup>
                  <InputLeftAddon width="110px" children="Sat." />
                  <Input
                    type="number"
                    maxW={20}
                    defaultValue={minutes.FIFTEEN}
                    min={0}
                    max={300}
                    value={saturdayTime}
                    onChange={(e) => setSaturdayTime(parseInt(e.target.value))}
                  />
                </InputGroup>
                <InputGroup>
                  <InputLeftAddon width="110px" children="Sun." />
                  <Input
                    type="number"
                    maxW={20}
                    defaultValue={minutes.FIFTEEN}
                    min={0}
                    max={300}
                    value={sundayTime}
                    onChange={(e) => setSundayTime(parseInt(e.target.value))}
                  />
                </InputGroup>
              </SimpleGrid>
            </Center>
            <br />
            <Button 
            mr="10px"
            onClick={() => navigate("/blocks")}>
              Cancel
            </Button>
            <Button type="submit" colorScheme="teal" isLoading={isSubmitting}>
              Update Block
            </Button>
          </FormControl>
        </form>
      )}
    </Center>
  );
};

export default EditSchedule;
