import FullCalendar from "@fullcalendar/react";
import React, { useState } from "react";
import { Flowmodoro } from "../../types/types";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import {
  EventClickArg,
  EventInput,
  EventSourceInput,
} from "@fullcalendar/core";
import SelectedRecordModal from "./SelectedRecordModal";

interface StatsCalendarProps {
  records: Flowmodoro[];
}

const getDateString = (recordDate: Date) => {
  const year = recordDate.getFullYear();
  const month =
    recordDate.getMonth() + 1 < 10
      ? `0${recordDate.getMonth() + 1}`
      : recordDate.getMonth() + 1;
  const day =
    recordDate.getDate() < 10
      ? `0${recordDate.getDate()}`
      : recordDate.getDate();
  return `${year}-${month}-${day}`;
};

const StatsCalendar = ({ records }: StatsCalendarProps) => {
  const [selectedRecord, setSelectedRecord] = useState<EventInput | undefined>(
    undefined
  );
  const events: EventSourceInput = records.map((record) => {
    const recordDate = new Date(record.date);
    return {
      title: `${record.scheduleName}`,
      url: "",
      date: getDateString(recordDate),
      extendedProps: {
        description: record.description,
        minutes: record.minutes,
      },
    };
  });

  return (
    <>
      <SelectedRecordModal
        isOpen={selectedRecord !== undefined}
        onClose={() => setSelectedRecord(undefined)}
        title={selectedRecord?.title ?? ""}
        minutes={selectedRecord?.extendedProps?.minutes ?? 0}
        description={selectedRecord?.extendedProps?.description ?? ""}
      />
      <FullCalendar
        eventStartEditable={false}
        eventDurationEditable={false}
        eventClick={(arg) =>
          setSelectedRecord({
            title: arg.event.title,
            date: arg.event.start!,
            extendedProps: {
              description: arg.event.extendedProps.description,
              minutes: arg.event.extendedProps.minutes,
            },
          })
        }
        handleWindowResize={false}
        initialView="dayGridMonth"
        contentHeight={"auto"}
        plugins={[dayGridPlugin, interactionPlugin]}
        eventContent={(eventInfo) => (
          <div
            style={{
              cursor: "pointer",
            }}
          >
            {eventInfo.event.title}
          </div>
        )}
        events={events}
      />
    </>
  );
};

export default StatsCalendar;
