import { CheckCircleIcon } from '@chakra-ui/icons'
import { motion } from 'framer-motion'
import React from 'react'

const AnimatedCheckmark = () => {
  return (
    <motion.div
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
  transition={{
    type: "spring",
    stiffness: 260,
    damping: 20,
  }}
> 
  <CheckCircleIcon color="teal.500" w={100} h={100}/>
</motion.div>
  )
}

export default AnimatedCheckmark