import { Flowmodoro } from "../../../types/types";
const todayObj = new Date();

const isFlowmodoroInWeek = (date : Date) => {
  const todayDate = todayObj.getDate();
  const todayDay = todayObj.getDay(); //day of the week 

  // get first date of week
  const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));
  firstDayOfWeek.setHours(0, 0, 0);

  // get last date of week
  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);
  lastDayOfWeek.setHours(23, 59, 59)

  // if date is equal or within the first and last dates of the week
  return date >= firstDayOfWeek && date <= lastDayOfWeek;
}

export const getWeekFlowmodoros = (records: Flowmodoro[]) => {

    return records.filter(record => isFlowmodoroInWeek(new Date(record.date)));
}