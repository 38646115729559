interface BlogRouteType {
    urlPath: string;
    markdownPath: string;
    helmetTitle: string;
    helmetDescription: string;
}

export const blogs: BlogRouteType[] = [
    {
        urlPath: "/blog/how-to-be-prolific",
        markdownPath: "/blog/BeProlific.md",
        helmetTitle: "Be Prolific",
        helmetDescription: "How can we have more creative output?"
    },
    {
        urlPath: "/blog/time-well-spent",
        markdownPath: "/blog/TimeWellSpent.md",
        helmetTitle: "Time Well Spent",
        helmetDescription: "How can we better spend our time?"
    },
    {
        urlPath: "/blog/how-my-app-helped-me-build-my-app",
        markdownPath: "/blog/HowMyAppHelpedMeBuildMyApp.md",
        helmetTitle: "How My App Helped Me Build My App",
        helmetDescription: "The story about how Flowmodoro helped me build Flowmodoro"
    },
    {
        urlPath: "/blog/how-to-incorporate-user-feedback",
        markdownPath: "/blog/HowToIncorporateUserFeedback.md",
        helmetTitle: "How To Incorporate User Feedback",
        helmetDescription: "We know it's important to get user feedback - but what do we do with it?"
    },
    {
        urlPath: "/blog/how-do-we-measure-productivity",
        markdownPath: "/blog/HowDoWeMeasureProductivity.md",
        helmetTitle: "How do we measure productivity?",
        helmetDescription: "How can we tell the difference between productive and non productive actions?"
    },
    {
        urlPath: "/blog/flowmodoro-vs-pomodoro",
        markdownPath: "/blog/FlowmodoroVsPomodoro.md",
        helmetTitle: "Flowmodoro Vs Pomodoro",
        helmetDescription: "Differences between Flowmodoro and Pomodoro and when to use one over the other"
    },
     
]