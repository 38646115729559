import {
  Card,
  CardBody,
  Center,
  Heading,
  Wrap,
  WrapItem,
  Button,
} from "@chakra-ui/react";
import React from "react";
import { Navbar } from "../../components/Navbar";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

interface Guide {
  name: string;
  description: string;
  link: string;
}

const guides: Guide[] = [
  {
    name: "Flowmodoro 101",
    description: "An intro course on Flowmodoro",
    link: "/guides/flowmodoro101",
  },
  {
    name: "Block Tips",
    description: "3 tips to create better blocks",
    link: "/guides/tips-for-creating-blocks",
  },
];

const Guides = () => {
  const navigate = useNavigate();
  return (
    <Center
      style={{
        height: "100vh",
        textAlign: "center",
        backgroundColor: "#f7fafc",
      }}
    >
      <Navbar />
      <Helmet>
        <title>Guides | Flowmodoro</title>
        <meta
          name="description"
          content="A page full of Flowmodoro guides on all things Flowmodoro."
        />
      </Helmet>
      <div>
        <Heading>Guides</Heading>
        <br />
        <Wrap justify={"center"}>
          {guides.map((guide) => (
            <WrapItem>
              <Card
                style={{
                  cursor: "pointer",
                }}
                onClick={() => navigate(guide.link)}
              >
                <CardBody>
                  <Heading size="sm">{guide.name}</Heading>
                  <div>{guide.description}</div>
                </CardBody>
              </Card>
            </WrapItem>
          ))}
        </Wrap>
      </div>
    </Center>
  );
};

export default Guides;
