import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Flowmodoro } from "../../types/types";
import { editFlowmodoroRecord } from "../../api/flowmodoroRecords";

interface EditRecordProps {
  setSelectedRecord: Function;
  record: Flowmodoro;
  updateRecords: Function;
}

const EditRecordModal = ({ record, setSelectedRecord, updateRecords }: EditRecordProps) => {
  const [description, setDescription] = useState(record.description ?? "");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  return (
    <Modal isOpen={true} onClose={() => setSelectedRecord(null)}>
       <ModalOverlay />
      <ModalContent>
      <ModalHeader>Edit Flowmodoro Description</ModalHeader>
       
        <ModalBody>
          <div>
            <div>
            <strong>Schedule name:</strong> {record.scheduleName}
            </div>
            <div>
            <strong>Minutes:</strong> {record.minutes}
            </div>
            <div>
            <strong>Date</strong> {new Date(record.date).toLocaleString()}
            </div>
          </div>
            <Textarea value={description} onChange={(e) => setDescription(e.target.value)}/>
        </ModalBody>
        <ModalFooter>
            <Button 
            isLoading={isSubmitting}
            mr={3} onClick={() => setSelectedRecord(null)}>
              Cancel
            </Button>
            <Button colorScheme="teal" 
            isLoading={isSubmitting}
            onClick={() => {
              setIsSubmitting(true);
              editFlowmodoroRecord({
                ...record,
                description
              }).then(() => {
                setIsSubmitting(false);
                setSelectedRecord(null);
                updateRecords();
                toast({
                  status: "success",
                  title: "Flowmodoro Description updated"
                });
              })
            }}>Submit</Button>
          </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditRecordModal;
