import axios from "axios";
import { API_URL, getAxiosConfig } from "./common";

export interface PreferencesType {
  isEmailNotificationsEnabled : boolean;
}

export const getPreferences = async () =>  await axios.get(`${API_URL}/preferences`, await getAxiosConfig());


export const updatePreferences = async (preferences : PreferencesType) => {
  const result = await axios.post(
    `${API_URL}/preferences`,
    preferences,
    await getAxiosConfig()
  );
  return result.data;
};

export const getSound = () : string => {
  const sound = localStorage.getItem("sound");
  if (sound === null || sound === "flow") {
    return "flow";
  } else if (sound === "cool" || sound === "energy" || sound === "night") {
    return sound;
  } else {
    return "flow";
  }
}
