import { StarIcon } from "@chakra-ui/icons";
import {
  Card,
  CardBody,
  Center,
  Icon,
  Progress,
  Text,
} from "@chakra-ui/react";
import React from "react";

interface BadgeCardProps {
  title: string;
  description?: string;
  color: string;
  progressValue?: number;
}

const BadgeCard = ({
  title,
  description,
  color,
  progressValue,
}: BadgeCardProps) => {
  return (
    <Card maxW={"xs"}>
      <CardBody color={color}>
        <Center>
          <Icon as={StarIcon} w={6} h={6} color={`${color}`} />
        </Center>
        <Text size="sm"><strong>{title}</strong></Text>
        {description && <Text size="xs">{description}</Text>}
        {progressValue && (
          <Progress
            colorScheme={"teal"}
            size="sm"
            value={progressValue}
            hasStripe
          />
        )}
      </CardBody>
    </Card>
  );
};

export default BadgeCard;
