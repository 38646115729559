import axios from "axios";
import { API_URL, getAxiosConfig } from "./common";
import { Flowmodoro, TYPES } from "../types/types";

export const getFlowmodoroRecords = async () => {
  const weeklyFlowmodoro = await axios.get(`${API_URL}/records`, await getAxiosConfig());
  return weeklyFlowmodoro;
};

export const addFlowmodoroRecord = async (flowmodoroRecord: Flowmodoro) => {
  const result = await axios.post(
    `${API_URL}/record`,
    { ...flowmodoroRecord, type: TYPES.RECORD },
    await getAxiosConfig()
  );
  return result;
};

export const editFlowmodoroRecord = async (flowmodoroRecord: Flowmodoro) => {
  const result = await axios.put(
    `${API_URL}/record`,
    { ...flowmodoroRecord, type: TYPES.RECORD },
    await getAxiosConfig()
  );
  return result;
};