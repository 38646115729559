import { Box, Button, Flex, Spacer } from "@chakra-ui/react";
import React, { useState } from "react";
import { Time } from "./Timer";
import { useNavigate } from "react-router-dom";
import { addFlowmodoroRecord } from "../../api/flowmodoroRecords";
import { getCurrentTime } from "../../utils/time";

interface ControlButtonsProps {
  isClockShowing: boolean;
  time: Time;
  wakeLock: any;
  setWakeLock: Function;
  setTime: Function;
  setIsClockShowing: Function;
  startFunc: Function;
  worker: Worker | null;
  isSubmitting: boolean;
  setIsSubmitting: Function;
  setIsEndSessionScreenOpen: Function;
}

const ControlButtons = ({
  time,
  wakeLock,
  setWakeLock,
  setIsClockShowing,
  isClockShowing,
  startFunc,
  worker,
  isSubmitting,
  setIsSubmitting,
  setIsEndSessionScreenOpen,
  setTime
}: ControlButtonsProps) => {
  const navigate = useNavigate();
  const pause = () => {
      worker?.postMessage({
        type: "stop",
      });
      setTime((currentVal: Time) => ({
        ...currentVal,
        initialMinutes: Math.ceil(currentVal.secondsRemaining / 60)
      }))
      setIsClockShowing(false);
      if (wakeLock) {
        wakeLock.release().then(() => {
          setWakeLock(null);
        });
      }
    }
  
  return (
    <Flex
      style={{
        width: "100%",
        marginBottom: ".2rem",
      }}
    >
      <Box>
        <Button
          size="lg"
          isLoading={isSubmitting}
          style={{
            width: "90px",
            backgroundColor: "#f78104",
            color: "white",
          }}
          onClick={() => {
            // if (time.totalSessionTime > 0) {
            //   setIsSubmitting(true);
            //   addFlowmodoroRecord({
            //     date: getCurrentTime(),
            //     scheduleName: time.name,
            //     minutes: time.totalSessionTime,
            //     description: `Completed on ${new Date().toString()}`,
            //   }).then(() => {
            //     sessionStorage.removeItem(time.name);
            //     navigate("/blocks");
            //   });
            // } else {
            //   navigate("/blocks");
            // }
            if (time.totalSessionTime > 0) {
              setIsEndSessionScreenOpen(true);
              pause();
            } else {
              navigate("/blocks")
            }
            
          }}
        >
          End
        </Button>
      </Box>
      <Spacer />
      <Box>
        {isClockShowing ? (
          <Button
            size="lg"
            style={{
              width: "90px",
            }}
            onClick={() => pause()}
          >
            Pause
          </Button>
        ) : (
          <Button
            isDisabled={isSubmitting}
            style={{
              width: "90px",
            }}
            size="lg"
            colorScheme="teal"
            onClick={() => startFunc(time.secondsRemaining)}
          >
            Start
          </Button>
        )}
      </Box>
    </Flex>
  );
};

export default ControlButtons;
