import React from "react";
import Typewriter from "typewriter-effect";
import { Button, Center, Container, Heading, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const HomeIntro = () => {
  const navigate = useNavigate();
  return (
    <section
      style={{
        textAlign: "center",
        paddingTop: "10rem",
        marginBottom: "10vh",
      }}
    >
      <Center>
        <Container>
          <h1 style={{
            fontSize: "3rem",
            fontWeight: "500",
            color: "teal"
          }}>Flowmodoro</h1>
          <br />
          <Text color="grey" fontSize="2xl">
            <Typewriter
              options={{
                delay: 50,
                strings: [
                  "Write more blogs",
                  "Program more apps",
                  "Read more books",
                  "Write more books",
                ],
                autoStart: true,
                loop: true,
              }}
            />
          </Text>
          <Text color="grey" fontSize="xl">
            than ever before.
          </Text>

          <Button
            colorScheme="teal"
            size={"lg"}
            style={{
              marginTop: "5vh",
            }}
            onClick={() => navigate("/login")}
          >
            Get Started
          </Button>
        </Container>
      </Center>
    </section>
  );
};

export default HomeIntro;
