import { Container, Link } from "@chakra-ui/react";
import React from "react";

const BeProlific = () => {
  return (
    <section
      style={{
        backgroundColor: "teal",
        color: "white",
        paddingTop: "3rem",
        paddingBottom: "5rem",
      }}
    >
      <Container>
        <h2
          style={{
            textAlign: "center",
            fontSize: "2rem",
            fontWeight: 500,
          }}
        >
          What is Flowmodoro?
        </h2>
        <br />
        <p
          style={{
            fontSize: "1.125rem",
          }}
        >
          Flowmodoro is a powerful productivity app that helps you spend more
          time on things that matter most.
        </p>
        <br />
        <p
          style={{
            fontSize: "1.125rem",
          }}
        >
          Rather than completing your projects at the last minute or working in
          binges, Flowmodoro helps and encourages you to make daily progress
          towards your goals. Read more about it in our{" "}
          <Link href="/guides/flowmodoro101">
            <p
              style={{
                textDecorationLine: "underline",
              }}
            >
              Flowmodoro 101 Guide.
            </p>
          </Link>
        </p>
      </Container>
    </section>
  );
};

export default BeProlific;
