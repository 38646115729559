import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import ListSchedules from "./pages/Blocks/Blocks";
import Home from "./pages/Home/Home";
import Stats from "./pages/Stats/Stats";
import EditSchedule from "./pages/EditBlock/EditBlock";
import Preferences from "./pages/Preferences/Preferences";
import Timer from "./pages/Timer/Timer";
import Feedback from "./pages/Feedback/Feedback";
import Records from "./pages/Records/Records";
import Badges from "./pages/Badges/Badges";
import BlogEntry from "./pages/Blog/BlogEntry";
import Guides from "./pages/Guides/Guides";
import Blog from "./pages/Blog/Blog";
import { blogs } from "./pages/Blog/blogs";
import { FlowmodoroProvider } from "./context/FlowmodoroContext";

const App = () => {
  return (
    <>
    <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context" : "https://schema.org",
            "@type" : "WebSite",
            "name" : "Flowmodoro",
            "url" : "https://flowmodoro.com/"
          }),
        }}
      />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/guides" element={<Guides />} />
          <Route
            path="/guides/flowmodoro101"
            element={
              <BlogEntry
                path="/guides/Guide.md"
                helmetDescription="An introduction to Flowmodoro."
                helmetTitle="Flowmodoro 101"
              />
            }
          />
          <Route
            path="/guides/tips-for-creating-blocks"
            element={
              <BlogEntry
                path="/guides/TipsForCreatingBlocks.md"
                helmetTitle={"Creating Block Tips"}
                helmetDescription={"Tips for creating blocks in Flowmodoro."}
              />
            }
          />
          <Route path="/blog" element={<Blog />} />
          {blogs.map((blog) => (
            <Route
              path={blog.urlPath}
              element={
                <BlogEntry
                  path={blog.markdownPath}
                  helmetTitle={blog.helmetTitle}
                  helmetDescription={blog.helmetDescription}
                />
              }
            />
          ))}
          <Route element={<FlowmodoroProvider />}>
            <Route path="/blocks" element={<ListSchedules />} />
            <Route path="/stats" element={<Stats />} />
            <Route path="/records" element={<Records />} />
            <Route path="/timer" element={<Timer />} />
            <Route path="/badges" element={<Badges />} />
            <Route
              path="/blocks/edit/:scheduleName"
              element={<EditSchedule />}
            />
            <Route path="/preferences" element={<Preferences />} />
            <Route path="/feedback" element={<Feedback />} />
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
