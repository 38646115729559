import React from "react";
import { Flowmodoro } from "../../../types/types";
import { getWeekFlowmodoros } from "../utils/statWeekUtils";
import { getTotalMinutes, mapFlowmodoroTotals } from "../utils/statsUtils";
import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Spacer,
  Stat,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

interface WeekStatsProps {
  records: Flowmodoro[];
}

export const WeekStats = ({ records }: WeekStatsProps) => {
  const weekRecords = getWeekFlowmodoros(records);
  const totalMinutes = getTotalMinutes(weekRecords);
  // const days = Math.floor(totalMinutes / (60 * 24)).toFixed(0);
  // const leftoverMinutes = totalMinutes % (60 * 24);
  const hours = Math.floor(totalMinutes / 60).toFixed(0);
  const minutes = Math.floor(totalMinutes % 60).toFixed(0);
  //const flowmodoroTotals = mapFlowmodoroTotals(weekRecords);
  return (
    <Flex justifyContent={"center"}>
      <Card size="sm" minW="xs" maxW="sm" variant="outline">
        <CardBody>
          <Flex>
            <div>
              <strong>Your Weekly Statistics</strong>
            </div>
            <Spacer />
            <div>
              <Link to="/stats">
                <Text
                  style={{
                    color: "teal",
                  }}
                >
                  See more
                </Text>
              </Link>
            </div>
          </Flex>

          <StatGroup>
            <Stat size="md">
              <StatLabel>Flowmodoros</StatLabel>
              <StatNumber>{weekRecords.length}</StatNumber>
            </Stat>
            <Stat size="md">
              <StatLabel>Time</StatLabel>
              <StatNumber>
                {hours}h {minutes}m
              </StatNumber>
            </Stat>
          </StatGroup>
          {/* <div
        style={{
          height: "25vh",
        }}
      >
        <ResponsiveBar
          colors={["teal"]}
          data={flowmodoroTotals}
          indexBy={"dayOfWeek"}
          labelTextColor={"white"}
          keys={["minutes"]}
          padding={0.3}
          role="application"
          margin={{ bottom: 30, left: 30, top: 0 }}
          ariaLabel="Nivo bar chart demo"
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: "middle",
            legendOffset: -40,
            truncateTickAt: 0,
          }}
        />
      </div> */}
        </CardBody>
      </Card>
    </Flex>
  );
};
