import React, { useState } from "react";
import {
  AbsoluteCenter,
  Box,
  Center,
  Divider,
  Flex,
  Spinner,
  Stack,
} from "@chakra-ui/react";
import { Navbar } from "../../components/Navbar";
import { useSchedules } from "../../hooks/useSchedules";
import { useRecords } from "../../hooks/useRecords";
import {
  getRecordsWithScheduleName,
  hasCompletedToday,
} from "./SchedulesUtils";
import { WeekStats } from "../Stats/Tabs/WeekStats";
import BlockCard from "./BlockCard";
import { Helmet } from "react-helmet";
import NewBlockCard from "./NewBlockCard";
import { CheckIcon } from "@chakra-ui/icons";


export interface DeleteScheduleModalState {
  isOpen: boolean;
  name: string;
}

export interface NewBlock {
  isCreating: boolean;
  isSubmitting: boolean;
  name: string;
  minutes: number;
}

const ListBlocks = () => {
  const { isLoadingWeeklyFlowmodoros, weeklyFlowmodoros, refreshSchedules } =
    useSchedules();
  const { records, isLoadingRecords } = useRecords();
  const [newBlock, setNewBlock] = useState<NewBlock>({
    isCreating: false,
    name: "",
    minutes: 15,
    isSubmitting: false,
  });

  const completedBlocks = [];
  const todoBlocks = [];

  for (let i = 0; i < weeklyFlowmodoros.length; i++) {
    const recordsWithScheduleName = getRecordsWithScheduleName(
      records,
      weeklyFlowmodoros[i]
    );
    if (
      hasCompletedToday(
        weeklyFlowmodoros[i],
        recordsWithScheduleName,
        new Date()
      )
    ) {
      completedBlocks.push(weeklyFlowmodoros[i]);
    } else {
      todoBlocks.push(weeklyFlowmodoros[i]);
    }
  }

  return (
    <>
      <Navbar />
      <Helmet>
        <title>Blocks | Flowmodoro</title>
        <meta name="description" content="List of all your blocks." />
      </Helmet>
      {!isLoadingWeeklyFlowmodoros && !isLoadingRecords ? (
        <Flex
          justify="center"
          style={{
            height: "100vh",
            backgroundColor: "#f7fafc",
            marginTop: "10vh",
          }}
        >
          <div
          style={{
            width: "80vw"
          }}
          >
            <WeekStats records={records} />
            {weeklyFlowmodoros.length === 0 ? (
              <Center>
                <Stack
                  style={{
                    textAlign: "center",
                  }}
                >
                  <div>No Blocks to display.</div>
                  <NewBlockCard
                    newBlock={newBlock}
                    setNewBlockCard={setNewBlock}
                    refreshSchedules={refreshSchedules}
                  />
                </Stack>
              </Center>
            ) : (
              <>
                <Box position="relative" padding="5">
                  <Divider />
                  <AbsoluteCenter bg="#f7fafc" px="4">
                    <strong>Todo</strong>
                  </AbsoluteCenter>
                </Box>
                <Stack spacing="2" align="center">
                  {todoBlocks.length === 0 && (
                    <div
                      style={{
                        color: "teal",
                        textAlign: "center",
                      }}
                    >
                      <CheckIcon />
                      <div>All done for today!</div>
                    </div>
                  )}
                  {todoBlocks.map((weeklyFlowmodoro) => (
                    <BlockCard
                      records={records}
                      isLoading={isLoadingRecords}
                      weeklyFlowmodoro={weeklyFlowmodoro}
                      isCompleted={false}
                      refreshSchedules={refreshSchedules}
                    />
                  ))}
                  <NewBlockCard
                    newBlock={newBlock}
                    setNewBlockCard={setNewBlock}
                    refreshSchedules={refreshSchedules}
                  />
                </Stack>
                <Box position="relative" padding="5">
                  <Divider />
                  <AbsoluteCenter bg="#f7fafc" px="4">
                    <strong>Done</strong>
                  </AbsoluteCenter>
                </Box>
                <Stack align="center">
                  {completedBlocks.length === 0 && (
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      When you finish your blocks, they'll end up here.
                    </div>
                  )}
                  {completedBlocks.map((weeklyFlowmodoro) => (
                    <BlockCard
                      refreshSchedules={refreshSchedules}
                      records={records}
                      isLoading={isLoadingRecords}
                      weeklyFlowmodoro={weeklyFlowmodoro}
                      isCompleted={true}
                    />
                  ))}
                </Stack>
              </>
            )}

            <br />
          </div>
        </Flex>
      ) : (
        <Center
          style={{
            height: "100vh",
          }}
        >
          <Spinner />
        </Center>
      )}
    </>
  );
};

export default ListBlocks;
