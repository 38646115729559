import { Center, Heading, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Clock from "./Clock";
import { useLocation, useNavigate } from "react-router-dom";
import { addFlowmodoroRecord } from "../../api/flowmodoroRecords";
import { Navbar } from "../../components/Navbar";
import SetMinutesSlider from "./SetMinutesSlider";
import SessionStats from "./SessionStats";
import ControlButtons from "./ControlButtons";
import AlarmModal from "./AlarmModal";
import { getCurrentTime } from "../../utils/time";
import { useSound } from "use-sound";
import { Helmet } from "react-helmet";
import { getSound } from "../../api/preferences";
import EndSessionDrawer from "./EndSessionDrawer";

export interface Time {
  initialMinutes: number;
  secondsRemaining: number;
  name: string;
  totalSessionTime: number;
}

const date = new Date();

const isSameDate = (dateOne: Date, dateTwo: Date) => {
  if (
    dateOne.getDay() === dateTwo.getDay() &&
    dateOne.getMonth() === dateTwo.getMonth() &&
    dateOne.getFullYear() === dateTwo.getFullYear()
  ) {
    return true;
  }
  return false;
};

const intervalFunc = ({
  setTime,
  isSubmitting,
  time,
  setWorker,
  wakeLock,
  setWakeLock,
  workerSecondsRemaining,
  currentTime
}: {
  workerSecondsRemaining: number;
  time: Time;
  setTime: Function;
  isSubmitting: boolean;
  setWorker: Function;
  wakeLock: any;
  setWakeLock: Function;
  currentTime: number;
}) => {
  setTime(
    ({ name, secondsRemaining, initialMinutes, totalSessionTime }: Time) => {
      if (totalSessionTime !== 0 && !isSubmitting) {
        sessionStorage.setItem(
          time.name,
          JSON.stringify({
            currentTimeMillis: currentTime,
            totalSessionTime: secondsRemaining === 0? totalSessionTime + 1 : totalSessionTime,
          })
        );
      }

      if (secondsRemaining === 1) {
        setWorker((currentWorker: any) => {
          currentWorker?.postMessage({ type: "stop" });
          return currentWorker;
        });
        if (wakeLock) {
          wakeLock.release().then(() => {
            setWakeLock(null);
          });
        }
        return {
          name,
          initialMinutes,
          secondsRemaining: 0,
          totalSessionTime: totalSessionTime + 1,
        };
      }

      if (
        initialMinutes * 60 !== secondsRemaining &&
        secondsRemaining % 60 === 0
      ) {
        return {
          name,
          initialMinutes,
          secondsRemaining: workerSecondsRemaining,
          totalSessionTime: totalSessionTime + 1,
        };
      }
      return {
        name,
        initialMinutes,
        secondsRemaining: workerSecondsRemaining,
        totalSessionTime,
      };
    }
  );
};

const Timer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [worker, setWorker] = useState<null | Worker>(null);
  const [currentTime, setCurrentTime] = useState<number>(date.getTime());
  const [time, setTime] = useState<Time>({
    name: location.state?.name ?? "Timer",
    initialMinutes: 30,
    secondsRemaining: 30 * 60,
    totalSessionTime: 0,
  });

  const [wakeLock, setWakeLock] = useState<null | any>(null);
  const [isEndSessionScreenOpen, setIsEndSessionScreenOpen] = useState(false);
  const [isAlarmModalOpen, setIsAlarmModalOpen] = useState(false);
  const [isClockShowing, setIsClockShowing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [playAlarm, { stop: stopPenguin }] = useSound(
    `${process.env.PUBLIC_URL}/sounds/${getSound()}.m4a`
  );

  useEffect(() => {
    if (location.state?.name == null) {
      navigate("/blocks");
    }
  }, [])

  useEffect(() => {
    if (document.visibilityState === "visible" && wakeLock) {
      try {
        const anyNav: any = navigator;
        if ("wakeLock" in navigator) {
          anyNav["wakeLock"].request("screen").then((result: any) => {
            setWakeLock(result);
          });
        }
      } catch (err) {
        // the wake lock request fails - usually system related, such being low on battery
        console.log(`${err}`);
      }
    }
  }, [document.visibilityState]);

  useEffect(() => {
    const sessionStorageCurrentSession = sessionStorage.getItem(time.name);
    if (sessionStorageCurrentSession) {
      const { totalSessionTime, currentTimeMillis } = JSON.parse(
        sessionStorageCurrentSession
      );
      if (isSameDate(date, new Date(currentTimeMillis))) {
        setTime({
          ...time,
          totalSessionTime,
        });
        setCurrentTime(currentTimeMillis);
      }
    }
  }, []);

  useEffect(() => {
    if (time.secondsRemaining === 0) {
      playAlarm();
      setIsAlarmModalOpen(true);
    }
  }, [time.secondsRemaining]);

  useEffect(() => {
    if ("Notification" in window) {
      if (Notification?.permission === "granted") {
      } else if (Notification && Notification?.permission !== "denied") {
        // We need to ask the user for permission
        try {
          Notification.requestPermission().then(() =>
            console.log("permission granted")
          );
        } catch (error) {
          // Safari doesn't return a promise for requestPermissions and it
          // throws a TypeError. It takes a callback as the first argument
          // instead.
          if (error instanceof TypeError) {
            Notification.requestPermission(() => {
              console.log("permission granted");
            });
          } else {
            throw error;
          }
        }
      }
    }

    const webworker = new Worker(`${process.env.PUBLIC_URL}/worker.js`);

    webworker.addEventListener("message", (e) =>
      intervalFunc({
        workerSecondsRemaining: e.data.secondsRemaining,
        setTime,
        isSubmitting,
        time,
        setWorker,
        wakeLock,
        setWakeLock,
        currentTime
      })
    );

    setWorker(webworker);

    return () => {
      webworker.terminate();
    };
  }, []);
  const startFunc = (initSeconds: number) => {
    worker!.postMessage({
      type: "start",
      secondsRemaining: initSeconds,
    });

    try {
      const anyNav: any = navigator;
      if ("wakeLock" in navigator) {
        anyNav["wakeLock"].request("screen").then((result: any) => {
          setWakeLock(result);
        });
      }
    } catch (err) {
      // the wake lock request fails - usually system related, such being low on battery
      console.log(`${err}`);
    }
    setIsClockShowing(true);
  };

  return (
    <>
      <Navbar />
      <Helmet>
        <title>{`Timer | Flowmodoro`}</title>
      </Helmet>
      <EndSessionDrawer
        time={time}
        isOpen={isEndSessionScreenOpen}
        onClose={() => setIsEndSessionScreenOpen(false)}
      />
      <AlarmModal
        isOpen={isAlarmModalOpen}
        onStopButtonClick={() => {
          stopPenguin();
          setIsAlarmModalOpen(false);
          setIsClockShowing(false);
          setIsEndSessionScreenOpen(true);
        }}
        onClose={() => {
          stopPenguin();
          setIsAlarmModalOpen(false);
          setIsClockShowing(false);
        }}
        handleRepeat={() => {
          setTime({
            ...time,
            secondsRemaining: time.initialMinutes * 60,
          });
          setIsAlarmModalOpen(false);
          stopPenguin();
          startFunc(time.initialMinutes * 60);
        }}
      />
      <Center
        style={{
          backgroundColor: "#f7fafc",
          height: "100vh",
        }}
      >
        <VStack>
          <Heading size="lg">{time.name}</Heading>
          {isClockShowing ? (
            <Clock time={time} />
          ) : (
            <SetMinutesSlider
              time={time}
              setTime={setTime}
            />
          )}
          <ControlButtons
            startFunc={startFunc}
            time={time}
            wakeLock={wakeLock}
            setWakeLock={setWakeLock}
            setTime={setTime}
            setIsClockShowing={setIsClockShowing}
            isClockShowing={isClockShowing}
            setIsEndSessionScreenOpen={setIsEndSessionScreenOpen}
            worker={worker}
            isSubmitting={isSubmitting}
            setIsSubmitting={setIsSubmitting}
          />
          <SessionStats
            time={time}
            minutesDue={location.state?.minutesDue ?? 0}
            minutesSpent={location.state?.minutesSpent ?? 0}
          />
        </VStack>
      </Center>
    </>
  );
};

export default Timer;
