import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { postWeeklyFlowmodoro } from "../../api/weeklyFlowmodoroSchedule";
import { NewBlock } from "./Blocks";

interface NewBlockCardProps {
  newBlock: NewBlock;
  setNewBlockCard: Function;
  refreshSchedules: Function;
}

const NewBlockCard = ({ newBlock, setNewBlockCard, refreshSchedules }: NewBlockCardProps) => {
  const toast = useToast();

  if (!newBlock.isCreating) {
    return (
      <Button
        style={{
          marginBottom: "1vh",
        }}
        colorScheme="teal"
        onClick={() =>
          setNewBlockCard({
            ...newBlock,
            isCreating: true,
          })
        }
      >
        + New Block
      </Button>
    );
  }

  return (
    <Card>
      <CardHeader>
        <Heading size="md">New Block</Heading>
      </CardHeader>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setNewBlockCard({
            ...newBlock,
            isSubmitting: true,
          });
          postWeeklyFlowmodoro({
            name: newBlock.name,
            mondayTime: newBlock.minutes,
            tuesdayTime: newBlock.minutes,
            wednesdayTime: newBlock.minutes,
            thursdayTime: newBlock.minutes,
            fridayTime: newBlock.minutes,
            saturdayTime: newBlock.minutes,
            sundayTime: newBlock.minutes,
          })
            .then(() => {
              toast({
                title: "Block successfully created.",
                status: "success",
              });
              refreshSchedules();
              setNewBlockCard({
                name: "",
                minutes: 15,
                isCreating: false,
                isSubmitting: false,
              });
            })
            .catch((e) => {
              setNewBlockCard({
                name: "",
                minutes: 15,
                isCreating: false,
                isSubmitting: false,
              });
              console.log(e.message);
              toast({
                title: `An error occured`,
                status: "error",
              });
              console.log("AN ERROR OCCURED");
            });
        }}
      >
        <CardBody>
          <FormControl
            isRequired
            style={{
              textAlign: "center",
            }}
          >
            <HStack>
              <div>
                <FormLabel textAlign={"center"}>
                  Name (max: 15 characters)
                </FormLabel>
                <Input
                  disabled={newBlock.isSubmitting}
                  type="text"
                  minLength={1}
                  maxLength={15}
                  aria-label="Name"
                  value={newBlock.name}
                  onChange={(e) => {
                    if (!/[^a-zA-Z0-9 ]/.test(e.target.value)) {
                      setNewBlockCard({
                        ...newBlock,
                        name: e.target.value,
                      });
                    }
                  }}
                />
              </div>
              <div>
                <FormLabel textAlign={"center"}>Minutes per day</FormLabel>
                <Input
                  type="number"
                  width="110px"
                  disabled={newBlock.isSubmitting}
                  defaultValue={15}
                  min={0}
                  max={300}
                  value={newBlock.minutes}
                  onChange={(e) =>
                    setNewBlockCard({
                      ...newBlock,
                      minutes: parseInt(e.target.value),
                    })
                  }
                />
              </div>
            </HStack>
          </FormControl>
        </CardBody>
        <Divider />
        <CardFooter justifyContent={"right"}>
          <ButtonGroup spacing="2" alignItems={"right"}>
            <Button
              isDisabled={newBlock.isSubmitting}
              onClick={() => {
                setNewBlockCard({
                  ...newBlock,
                  name: "",
                  minutes: 15,
                  isCreating: false,
                });
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              type="submit"
              isLoading={newBlock.isSubmitting}
            >
              Submit
            </Button>
          </ButtonGroup>
        </CardFooter>
      </form>
    </Card>
  );
};

export default NewBlockCard;
