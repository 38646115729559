import { Button, Center, Heading } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

const GetStarted = () => {
  const navigate = useNavigate();
  return (
    <section
      style={{
        color: "white",
        backgroundColor: "teal"
      }}
    >
      <Center
        style={{
          height: "60vh",
          textAlign: "center",
        }}
      >
        <div>
          <Heading>Get started today!</Heading>
          <Button
            size={"lg"}
            style={{
              margin: "10px",
            }}
            onClick={() => navigate("/login")}
          >
            Get Started
          </Button>
        </div>
      </Center>
    </section>
  );
};

export default GetStarted;
