import React from "react";
import { useBadges } from "../../hooks/useBadges";
import {
  Center,
  Heading,
  Spinner,
  StatGroup,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
} from "@chakra-ui/react";
import { Navbar } from "../../components/Navbar";
import MonthlyBadges from "./MonthlyBadges";
import { Helmet } from "react-helmet";

const Badges = () => {
  const { badges, isLoading } = useBadges();

  return (
    <>
      <Navbar />
      <Helmet>
        <title>Badges | Flowmodoro</title>
        <meta name="description" content="Badges for completing Flowmodoros" />
      </Helmet>
      <Center
        style={{
          marginTop: "10vh",
          backgroundColor: "#f7fafc",
        }}
      >
        <div
          style={{
            width: "80vw",
            marginBottom: "10vh",
          }}
        >
          <Heading
            style={{
              marginBottom: "5vh",
              textAlign: "center",
            }}
          >
            Badges
          </Heading>
          {isLoading ? (
            <Center>
              <Spinner />
            </Center>
          ) : (
            <div>
              <Center style={{
                textAlign: "center"
              }}>
                <StatGroup>
                  <Stat>
                    <StatLabel>Total</StatLabel>
                    <StatNumber>{badges.length}</StatNumber>
                    <StatHelpText>Badges</StatHelpText>
                  </Stat>
                </StatGroup>
              </Center>

              {/* <Tabs isFitted variant="enclosed">
                <TabList mb="1em">
                  <Tab>Monthly Badges</Tab>
                  <Tab>All Time</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel> */}
                    <MonthlyBadges badges={badges} />
                  {/* </TabPanel>
                  <TabPanel>
                <AllTimeBadges />
              </TabPanel>
                </TabPanels>
              </Tabs> */}
            </div>
          )}
        </div>
      </Center>
    </>
  );
};

export default Badges;
