import { WeeklySchedule } from "../types/types";

export const getCurrentTime = () => new Date().getTime();

const getDayOfTheWeek = (date : Date) => {
    return date.getDay();
}

export const getDueFlowmodoro = (date : Date, weeklySchedule : WeeklySchedule) : number => {
    const dayOfTheWeek = getDayOfTheWeek(date);
    switch (dayOfTheWeek) {
        case 1:
            return weeklySchedule.mondayTime
        case 2:
            return weeklySchedule.tuesdayTime
        case 3:
            return weeklySchedule.wednesdayTime
        case 4:
            return weeklySchedule.thursdayTime
        case 5:
            return weeklySchedule.fridayTime
        case 6:
            return weeklySchedule.saturdayTime
        case 0:
            return weeklySchedule.sundayTime
    }
    return -1
}

export const getTimeString = (totalMinutes: number) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.floor(totalMinutes % 60);

    if (hours === 0) {
        return `${minutes.toFixed(0)} minutes`
    }
    return `${hours}h ${minutes}m`
}