import { useEffect, useState } from "react";
import { Flowmodoro, WeeklySchedule } from "../types/types";
import { addFlowmodoroRecord, getFlowmodoroRecords } from "../api/flowmodoroRecords";
import { useIsLoggedIn } from "./useIsLoggedIn";
import { useSchedules } from "./useSchedules";

const pushOldFlowmodoros = async (weeklyFlowmodoros: WeeklySchedule[]) => {
  if (weeklyFlowmodoros.length !== 0) {
    for (let i = 0; i < weeklyFlowmodoros.length; i++) {
      const weeklyFlowmodoro = weeklyFlowmodoros[i];
      const sessionStorageCurrentSession = sessionStorage.getItem(
        weeklyFlowmodoro.name
      );
      if (sessionStorageCurrentSession) {
        const { totalSessionTime, currentTimeMillis } = JSON.parse(
          sessionStorageCurrentSession
        );
        await addFlowmodoroRecord({
          date: currentTimeMillis,
          scheduleName: weeklyFlowmodoro.name,
          minutes: totalSessionTime,
          description: `Completed on ${new Date(currentTimeMillis).toString()}`,
        });
        sessionStorage.removeItem(weeklyFlowmodoro.name);
      }
    }
  }
};

export const useRecords = () => {
  const [records, setRecords] = useState<Flowmodoro[]>([]);
  const {weeklyFlowmodoros, isLoadingWeeklyFlowmodoros} = useSchedules();
  const [isLoadingRecords, setIsLoadingRecords] = useState(true);
  const { isLoggedIn, isLoadingUser } = useIsLoggedIn();

  const updateRecords = async () => {
    if (!isLoadingUser && isLoggedIn) {
      await pushOldFlowmodoros(weeklyFlowmodoros);
      const result = await getFlowmodoroRecords();
      setRecords(result.data);
      setIsLoadingRecords(false);
    }

    if (!isLoadingUser && !isLoggedIn) {
      window.location.replace("/login");
    }
  };

  useEffect(() => {
    if(!isLoadingWeeklyFlowmodoros) {
      updateRecords();
    }
  }, [isLoadingRecords, isLoggedIn, isLoadingUser, isLoadingWeeklyFlowmodoros]);

  return {
    records,
    setRecords,
    isLoadingRecords,
    setIsLoadingRecords,
    updateRecords
  };
};
