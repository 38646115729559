import React, { useEffect, useState } from "react";
import { useRecords } from "../../hooks/useRecords";
import { Navbar } from "../../components/Navbar";
import {
  Center,
  Heading,
  Select,
  Spinner,
} from "@chakra-ui/react";
import StatsDetails from "./StatsDetails";
import { Helmet } from "react-helmet";

const ALL = "ALL";
const Stats = () => {
  const [selectedSchedule, setSelectedSchedule] = useState(ALL);
  const [timeMap, setTimeMap] = useState(new Map());
  const { records, isLoadingRecords } = useRecords();

  useEffect(() => {
    const updatedMap = new Map();
    updatedMap.set(ALL, 0);
    for (let i = 0; i < records.length; i++) {
      if (updatedMap.has(records[i].scheduleName)) {
        updatedMap.set(
          records[i].scheduleName,
          updatedMap.get(records[i].scheduleName) + records[i].minutes
        );
      } else {
        updatedMap.set(records[i].scheduleName, records[i].minutes);
      }
      updatedMap.set(ALL, updatedMap.get(ALL) + records[i].minutes);
    }
    setTimeMap(updatedMap);
  }, [records]);

  const filteredRecords = records.filter((record) =>
    selectedSchedule === ALL ? true : record.scheduleName === selectedSchedule
  );

  return (
    <Center>
      <Navbar />
      <div
        style={{
          textAlign: "center",
          marginTop: "15vh",
        }}
      >
        <Helmet>
        <title>Stats | Flowmodoro</title>
      </Helmet>
        <Heading>Statistics</Heading>
        {isLoadingRecords ? (
          <Spinner />
        ) : (
          <>
            <Select
              onChange={(event) => {
                setSelectedSchedule(event.target.value);
              }}
              defaultValue={ALL}
            >
              {Array.from(timeMap.keys()).map((key) => (
                <option>{key}</option>
              ))}
            </Select>

                  <StatsDetails
                    records={filteredRecords}
                  />
          </>
        )}
      </div>
    </Center>
  );
};

export default Stats;
