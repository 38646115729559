import React, { Dispatch, ReactNode, SetStateAction, createContext, useContext, useEffect } from 'react'
import { useRecords } from '../hooks/useRecords'
import { Flowmodoro } from '../types/types';
import { Outlet, useNavigate } from 'react-router-dom';
import { useIsLoggedIn } from '../hooks/useIsLoggedIn';

interface FlowmodoroContextInterface {
    records: Flowmodoro[];
    setRecords : Dispatch<SetStateAction<Flowmodoro[]>>;
    updateRecords: Function;
    isLoadingRecords : boolean;
    setIsLoadingRecords : Dispatch<SetStateAction<boolean>>
  }

export const FlowmodoroContext = createContext<FlowmodoroContextInterface>({
    records: [],
    setRecords : () => {},
    updateRecords: () => {},
    isLoadingRecords: true,
    setIsLoadingRecords : () => {}
})

export const FlowmodoroProvider = () => {
  const { isLoggedIn, isLoadingUser } = useIsLoggedIn();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoadingUser) {
      if (!isLoggedIn) {
        navigate("/login");
      }
    }
  }, [isLoggedIn, isLoadingUser]);
  const records = useRecords();
  return (
    <FlowmodoroContext.Provider value={records}>
        <Outlet />
    </FlowmodoroContext.Provider>
  )
}

export const useFlowmodoro = () => {
    const context = useContext(FlowmodoroContext);
    return context;
}