import React from "react";
import {
  Container,
} from "@chakra-ui/react";

const HowItWorks = () => {
  return (
    <section
      style={{
        backgroundColor: "teal",
        color: "white",
        paddingTop: "3rem",
        paddingBottom: "5rem",
      }}
    >
      <Container>
        <h2
          style={{
            textAlign: "center",
            fontSize: "2rem",
            fontWeight: 500,
          }}
        >
          How does Flowmodoro work?
        </h2>

        <br />
        <p
          style={{
            fontSize: "1.125rem",
          }}
        >
          Flowmodoro works through a simple three step process:
        </p>
        <br />
        <p
          style={{
            fontSize: "1.125rem",
          }}
        >
          1. Set aside time each day to work on your tasks through{" "}
          <strong>blocks</strong>
        </p>

        <br />
        <p
          style={{
            fontSize: "1.125rem",
          }}
        >
          2. Track the time you spend working with our built-in Flowmodoro{" "}
          <strong>timer</strong>
        </p>

        <br />
        <p
          style={{
            fontSize: "1.125rem",
          }}
        >
          3. Once you complete the time allotted, be done for the day!
        </p>

        <br />
        <p
          style={{
            fontSize: "1.125rem",
          }}
        >
          If you keep this habit up over weeks, months, and years, you will be
          surprised at the results.
        </p>
        <br />
      </Container>
    </section>
  );
};

export default HowItWorks;
