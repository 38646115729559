import React, { useEffect, useState } from "react";
import { useRecords } from "../../hooks/useRecords";
import { Navbar } from "../../components/Navbar";
import {
  Center,
  Heading,
  Select,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import StatsCalendar from "./RecordsCalendar";
import { Helmet } from "react-helmet";
import RecordsCards from "./RecordsCards";

const ALL = "ALL";
const Records = () => {
  const [selectedSchedule, setSelectedSchedule] = useState(ALL);
  const [timeMap, setTimeMap] = useState(new Map());
  const { records, isLoadingRecords, updateRecords } = useRecords();

  useEffect(() => {
    const updatedMap = new Map();
    updatedMap.set(ALL, 0);
    for (let i = 0; i < records.length; i++) {
      if (updatedMap.has(records[i].scheduleName)) {
        updatedMap.set(
          records[i].scheduleName,
          updatedMap.get(records[i].scheduleName) + records[i].minutes
        );
      } else {
        updatedMap.set(records[i].scheduleName, records[i].minutes);
      }
      updatedMap.set(ALL, updatedMap.get(ALL) + records[i].minutes);
    }
    setTimeMap(updatedMap);
  }, [records]);

  const filteredRecords = records.filter((record) =>
    selectedSchedule === ALL ? true : record.scheduleName === selectedSchedule
  );

  return (
    <Center>
      <Navbar />
      <Helmet>
        <title>Records | Flowmodoro</title>
        <meta
          name="description"
          content="A list of all Flowmodoro recorded records."
        />
      </Helmet>
      <div
        style={{
          textAlign: "center",
          marginTop: "15vh",
        }}
      >
        <Heading>Records</Heading>
        {isLoadingRecords ? (
          <Spinner />
        ) : (
          <>
            <Select
              onChange={(event) => {
                setSelectedSchedule(event.target.value);
              }}
              defaultValue={ALL}
            >
              {Array.from(timeMap.keys()).map((key) => (
                <option>{key}</option>
              ))}
            </Select>
            <Tabs isLazy={true} isFitted={true} width={"80vw"}>
              <TabList>
                <Tab>Records</Tab>
                <Tab>Calendar</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <RecordsCards records={filteredRecords} updateRecords={updateRecords} />
                </TabPanel>
                <TabPanel>
                  <StatsCalendar records={filteredRecords} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </>
        )}
      </div>
    </Center>
  );
};

export default Records;
