import { Auth } from "aws-amplify";

export const API_URL = "https://9pxr8ssm7c.execute-api.us-east-1.amazonaws.com/Prod";

export const getAxiosConfig = async () =>  {
  return ({
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  })
}