import React from "react";
import { Badge } from "../../hooks/useBadges";
import {
  AbsoluteCenter,
  Box,
  Card,
  CardBody,
  CardHeader,
  Center,
  Divider,
  Flex,
  Heading,
  Icon,
  Spinner,
  Stack,
  StackDivider,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import BadgeCard from "./BadgeCard";
import { useRecords } from "../../hooks/useRecords";
import { Flowmodoro } from "../../types/types";
import { StarIcon } from "@chakra-ui/icons";

interface MonthlyBadgesProps {
  badges: Badge[];
}

interface MonthlyQuantityBadge {
  numFlowmodoros: number;
}

interface MonthlyTimeBadge {
  numMinutes: number;
}

const monthlyQuantityBadges: MonthlyQuantityBadge[] = [
  {
    numFlowmodoros: 5,
  },
  {
    numFlowmodoros: 10,
  },
  {
    numFlowmodoros: 20,
  },
];

const monthlyTimeBadges: MonthlyTimeBadge[] = [
  {
    numMinutes: 30,
  },
  {
    numMinutes: 150,
  },
  {
    numMinutes: 300,
  },
  {
    numMinutes: 600,
  },
];

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const meetsTimeRequirement = (
  monthlyBadge: MonthlyTimeBadge,
  badges: Badge[],
  currentDate: Date
): boolean => {
  const result = badges.some(
    (badge) =>
      badge.month === currentDate.getMonth() &&
      badge.year === currentDate.getFullYear() &&
      badge.name.includes(`${monthlyBadge.numMinutes} minutes`)
  );
  return result;
};

const meetsRequirement = (
  monthlyBadge: MonthlyQuantityBadge,
  badges: Badge[],
  currentDate: Date
): boolean => {
  const result = badges.some(
    (badge) =>
      badge.month === currentDate.getMonth() &&
      badge.year === currentDate.getFullYear() &&
      badge.name.includes(`${monthlyBadge.numFlowmodoros} Flowmodoros`)
  );
  return result;
};

const getTotalTime = (records: Flowmodoro[]) => {
  let totalTime = 0;
  for (let i = 0; i < records.length; i++) {
    totalTime += records[i].minutes;
  }
  return totalTime;
};

const currentDate = new Date();

const getCurrentMonthBadges = () => {};

const getPreviousMonthsBadges = (badges: Badge[]) => {
  const previousMonthBadges = badges.filter(
    (badge) =>
      currentDate.getMonth() !== badge.month ||
      currentDate.getFullYear() !== badge.year
  );

  const mappedBadges = new Map<string, Badge[]>();
  for (let i = 0; i < previousMonthBadges.length; i++) {
    const key = `${monthNames[previousMonthBadges[i].month]} - ${
      previousMonthBadges[i].year
    }`;
    if (mappedBadges.has(key)) {
      mappedBadges.get(key)?.push(previousMonthBadges[i]);
    } else {
      mappedBadges.set(key, [previousMonthBadges[i]]);
    }
  }

  return mappedBadges;
};

const MonthlyBadges = ({ badges }: MonthlyBadgesProps) => {
  const monthString = currentDate.toLocaleString("default", { month: "long" });
  const { records, isLoadingRecords } = useRecords();
  const filteredMonthRecords = records.filter(
    (record) =>
      currentDate.getMonth() === new Date(record.date).getMonth() &&
      currentDate.getFullYear() === new Date(record.date).getFullYear()
  );

  const totalTime = getTotalTime(filteredMonthRecords);
  const previousMonthBadges = getPreviousMonthsBadges(badges);
  if (isLoadingRecords) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }
  return (
    <div
      style={{
        textAlign: "center",
        backgroundColor: "#f7fafc",
      }}
    >
      <Box position="relative" padding="10">
        <Divider />
        <AbsoluteCenter bg="#f7fafc" px="4">
          <strong>Current Month</strong>
        </AbsoluteCenter>
      </Box>
      <Box position="relative" padding="10">
        <Divider />
        <AbsoluteCenter bg="#f7fafc" px="4">
          Quantity
        </AbsoluteCenter>
      </Box>
      <Center>
        <Wrap justify={"center"}>
          {monthlyQuantityBadges.map((monthlyBadge) => (
            <WrapItem>
              <BadgeCard
                title={`${monthlyBadge.numFlowmodoros} Flowmodoros`}
                description={`${monthString} ${currentDate.getFullYear()}`}
                color={
                  meetsRequirement(monthlyBadge, badges, currentDate)
                    ? "green"
                    : "lightgrey"
                }
                progressValue={
                  meetsRequirement(monthlyBadge, badges, currentDate)
                    ? 100
                    : (filteredMonthRecords.length /
                        monthlyBadge.numFlowmodoros) *
                      100
                }
              />
            </WrapItem>
          ))}
        </Wrap>
      </Center>
      <Box position="relative" padding="10">
        <Divider />
        <AbsoluteCenter bg="#f7fafc" px="4">
          Minutes
        </AbsoluteCenter>
      </Box>
      <Center>
        <Wrap justify={"center"}>
          {monthlyTimeBadges.map((monthlyBadge) => (
            <WrapItem>
              <BadgeCard
                title={`${monthlyBadge.numMinutes} minutes`}
                description={`${monthString} ${currentDate.getFullYear()}`}
                color={
                  meetsTimeRequirement(monthlyBadge, badges, currentDate)
                    ? "green"
                    : "lightgrey"
                }
                progressValue={
                  meetsTimeRequirement(monthlyBadge, badges, currentDate)
                    ? 100
                    : (totalTime / monthlyBadge.numMinutes) * 100
                }
              />
            </WrapItem>
          ))}
        </Wrap>
      </Center>

      <Box position="relative" padding="10">
        <Divider />
        <AbsoluteCenter bg="#f7fafc" px="4">
          <strong>Previous Months</strong>
        </AbsoluteCenter>
      </Box>
      <Center>
        <Stack spacing="4">
          {Array.from(previousMonthBadges.keys()).map((key) => (
            <Card maxW={"xs"}>
              <CardHeader>
                <Heading size="md">{key}</Heading>
              </CardHeader>
              <CardBody
                style={{
                  color: "green",
                }}
              >
                <Stack divider={<StackDivider />} spacing="2">
                  {previousMonthBadges.get(key)!.map((monthlyBadge) => (
                    <Flex justifyContent={"center"}>
                      <Icon as={StarIcon} w={6} h={6} color={`green`} />
                      <Heading size="xs" textTransform="uppercase">
                        {`${monthlyBadge.name}`}
                      </Heading>
                    </Flex>
                  ))}
                </Stack>
              </CardBody>
            </Card>
          ))}
        </Stack>
      </Center>
    </div>
  );
};

export default MonthlyBadges;
