import { Center } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Markdown from "react-markdown";
import ChakraUIRenderer from "chakra-ui-markdown-renderer";
import { Navbar } from "../../components/Navbar";
import remarkToc from "remark-toc";
import { Helmet } from "react-helmet";
import "./guide.css";

interface BlogEntryProps {
  path : string;
  helmetTitle : string;
  helmetDescription: string;
}

const BlogEntry = ({path, helmetDescription, helmetTitle} : BlogEntryProps) => {
  const [markdownText, setMarkdownText] = useState({ markdown: "" });
  useEffect(() => {
    // Get the contents from the Markdown file and put them in the React state, so we can reference it in render() below.
    fetch(path)
      .then((res) => res.text())
      .then((text) => setMarkdownText({ markdown: text }));
  }, []);
  return (
    <Center>
      <Helmet>
        <title>{helmetTitle} | Flowmodoro</title>
        <meta
          name="description"
          content={helmetDescription}
        />
      </Helmet>
      <Navbar />
      <div
        className="center"
        style={{
          marginTop: "10vh",
          width: "80vw",
          marginBottom: "10vh",
        }}
      >
        <Markdown
          remarkPlugins={[remarkToc]}
          components={ChakraUIRenderer()}
          children={markdownText.markdown}
          skipHtml
        />
      </div>
    </Center>
  );
};

export default BlogEntry;
