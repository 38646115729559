import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Spacer,
  Flex,
} from "@chakra-ui/react";

interface AlarmModalProps {
  onClose: Function;
  isOpen: boolean;
  handleRepeat: Function;
  onStopButtonClick: Function;
}

const AlarmModal = ({
  onClose,
  isOpen,
  handleRepeat,
  onStopButtonClick,
}: AlarmModalProps) => {
  return (
    <Modal
      size={"sm"}
      isOpen={isOpen}
      onClose={() => {
          onClose();
      }}
      isCentered
    >
      <ModalOverlay />
      <ModalCloseButton />
      <ModalContent>
        <ModalHeader>Continue Working?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex
            direction="column"
            style={{
              textAlign: "center",
            }}
          >
            <div
              style={{
                textAlign: "center",
                marginBottom: "2rem",
              }}
            >
              <div>Would you like to continue working?</div>
              <br />
              <Button
                size={"lg"}
                colorScheme="orange"
                onClick={() => {
                  onStopButtonClick();
                }}
              >
                No - End Flowmodoro
              </Button>
            </div>
            <Spacer />
            <div
              style={{
                marginBottom: "2rem",
              }}
            >
              <Button onClick={() => handleRepeat()}>
                Yes - Repeat
              </Button>
            </div>
            <Spacer />
            <div
              style={{
                marginBottom: "2rem",
              }}
            >
              <Button onClick={() => onClose()}>
                Yes - Change Time
              </Button>
            </div>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AlarmModal;
