import React, { useState } from "react";
import { Flowmodoro } from "../../types/types";
import EditRecordModal from "./EditRecordModal";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";

interface RecordsCardsProps {
  records: Flowmodoro[];
  updateRecords: Function;
}

const RecordsCards = ({ records, updateRecords }: RecordsCardsProps) => {
  const [selectedRecord, setSelectedRecord] = useState<Flowmodoro | null>(null);
  return (
    <div>
      {selectedRecord !== null && (
        <EditRecordModal
          setSelectedRecord={setSelectedRecord}
          record={selectedRecord}
          updateRecords={updateRecords}
        />
      )}
      <Stack spacing="2">
        {[...records].reverse().map((record) => (
          <Card
            style={{
              cursor: "pointer",
              textAlign: "left",
            }}
            onClick={() => {
              setSelectedRecord(record);
            }}
          >
            <CardBody>
              <Heading size="md" marginBottom={"4"}>
                {record.scheduleName}
              </Heading>
              <Stack divider={<StackDivider />} spacing="1">
                <Box>
                  <Heading size="xs" textTransform="uppercase">
                    Date
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {new Date(record.date).toDateString()}
                  </Text>
                </Box>
                <Box>
                  <Heading size="xs" textTransform="uppercase">
                    Minutes
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {record.minutes}
                  </Text>
                </Box>
                <Box>
                  <Heading size="xs" textTransform="uppercase">
                    Description
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {record.description}
                  </Text>
                </Box>
              </Stack>
            </CardBody>
          </Card>
        ))}
      </Stack>
    </div>
  );
};

export default RecordsCards;
