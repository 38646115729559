import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
  } from '@chakra-ui/react'

interface SelectedRecordModalProps {
    isOpen : boolean;
    onClose : Function;
    title: string;
    minutes: number;
    description: string;
}

const SelectedRecordModal = ({isOpen, onClose, title, minutes, description} : SelectedRecordModalProps) => {
    return (
    <Modal isOpen={isOpen} onClose={() => onClose()}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Minutes: {minutes}</Text>
            <Text>Description: {description}</Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={() => onClose()}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  )
}

export default SelectedRecordModal