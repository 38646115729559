import {
  AbsoluteCenter,
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  Spinner,
  Stack,
  Switch,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Navbar } from "../../components/Navbar";
import { getPreferences, getSound, updatePreferences } from "../../api/preferences";
import { Helmet } from "react-helmet";
import useSound from "use-sound";
import { CheckIcon } from "@chakra-ui/icons";

const handleSelectSound = (sound: string) => {
  localStorage.setItem("sound", sound);
};

const Preferences = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedSound, setSelectedSound] = useState(getSound());
  const [playFlow, { stop: stopFlow }] = useSound(
    `${process.env.PUBLIC_URL}/sounds/flow.m4a`
  );
  const [playCool, { stop: stopCool }] = useSound(
    `${process.env.PUBLIC_URL}/sounds/cool.m4a`
  );
  const [playEnergy, { stop: stopEnergy }] = useSound(
    `${process.env.PUBLIC_URL}/sounds/energy.m4a`
  );
  const [playNight, { stop: stopNight }] = useSound(
    `${process.env.PUBLIC_URL}/sounds/night.m4a`
  );

  useEffect(() => {
    const retrievePreferences = async () => {
      const response = await getPreferences();
      setIsChecked(response.data.isEmailNotificationsEnabled);
      setIsLoading(false);
    };
    retrievePreferences();
  }, []);

  if (isLoading) {
    return (
      <Center
        style={{
          height: "100vh",
        }}
      >
        <Spinner />
      </Center>
    );
  }
  return (
    <Center
      style={{
        marginTop: "15vh",
      }}
    >
      <Navbar />
      <Helmet>
        <title>Preferences | Flowmodoro</title>
        <meta name="description" content="Flowmodoro preferences" />
      </Helmet>
      <div>
        <Heading>Preferences</Heading>
        <Box position="relative" padding="5">
          <Divider/>
          <AbsoluteCenter bg="#f7fafc" px="4">
            <strong>Sounds</strong>
          </AbsoluteCenter>
        </Box>

        <Stack direction="column" spacing={4} align="center">
          <Button
            rightIcon={selectedSound === "flow" ? <CheckIcon /> : <></>}
            onClick={() => {
              handleSelectSound("flow");
              setSelectedSound("flow");
              playFlow();
              stopCool();
              stopEnergy();
              stopNight();
            }}
            colorScheme="teal"
            variant="outline"
          >
            Flow
          </Button>
          <Button
            rightIcon={selectedSound === "cool" ? <CheckIcon /> : <></>}
            onClick={() => {
              handleSelectSound("cool");
              setSelectedSound("cool");
              playCool();
              stopFlow();
              stopEnergy();
              stopNight();
            }}
            colorScheme="blue"
            variant="outline"
          >
            Cool
          </Button>
          <Button
            rightIcon={selectedSound === "energy" ? <CheckIcon /> : <></>}
            onClick={() => {
              handleSelectSound("energy");
              setSelectedSound("energy");
              playEnergy();
              stopCool();
              stopFlow();
              stopNight();
            }}
            colorScheme="green"
            variant="outline"
          >
            Energy
          </Button>
          <Button
            rightIcon={selectedSound === "night" ? <CheckIcon /> : <></>}
            onClick={() => {
              handleSelectSound("night");
              setSelectedSound("night");

              playNight();
              stopCool();
              stopEnergy();
              stopFlow();
            }}
            colorScheme="purple"
            variant="outline"
          >
            Night
          </Button>
        </Stack>
        <Box position="relative" padding="5">
          <Divider />
          <AbsoluteCenter bg="#f7fafc" px="4">
            <strong>Email</strong>
          </AbsoluteCenter>
        </Box>
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="email-alerts" mb="0">
            Enable email alerts?
          </FormLabel>
          <Switch
            isChecked={isChecked}
            onChange={(event) => {
              if (isChecked) {
                updatePreferences({
                  isEmailNotificationsEnabled: false,
                }).then(() => setIsChecked(false));
              } else {
                updatePreferences({
                  isEmailNotificationsEnabled: true,
                }).then(() => setIsChecked(true));
              }
            }}
            id="email-alerts"
          />
        </FormControl>
      </div>
    </Center>
  );
};

export default Preferences;
