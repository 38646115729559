import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
} from "@chakra-ui/react";
import { deleteWeeklyFlowmodoro } from "../api/weeklyFlowmodoroSchedule";

export interface DeleteScheduleModalProps {
  name: string;
  isOpen: boolean;
  setIsOpen: Function;
  refreshSchedules: Function;
}

const deleteSchedule = (name: string, setIsOpen: Function, refreshSchedules : Function, toast : Function) => {
  deleteWeeklyFlowmodoro(name)
    .then(() => {
      setIsOpen(false);
      toast({
        title: "Block successfully deleted",
        status: "success"
      });
      refreshSchedules();
      
    })
    .catch(() => {
      console.error("NOT DELETED ");
    });
};

const DeleteScheduleModal = ({
  name,
  isOpen,
  setIsOpen,
  refreshSchedules
}: DeleteScheduleModalProps) => {
  const toast = useToast();
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Block</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Are you sure that you want to delete block: {name}?
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="red"
            mr={3}
            onClick={() => {
              deleteSchedule(name, setIsOpen, refreshSchedules, toast);
            }}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteScheduleModal;
