import { Flowmodoro } from "../../../types/types";

export const getHoursSpentMonth = (records: Flowmodoro[]) => {
  let minutesSpentMonth = 0;
  const recordsCopy = [...records].sort().reverse();
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  for (let i = 0; i < recordsCopy.length; i++) {
    if (currentMonth === new Date(recordsCopy[i].date).getMonth()) {
      minutesSpentMonth += recordsCopy[i].minutes;
    }
  }
  return Math.floor(minutesSpentMonth / 60).toFixed(0);
}

export const getHoursSpentYear = (records : Flowmodoro[]) => {
  let minutesSpentMonth = 0;
  const recordsCopy = [...records].sort().reverse();
  const currentDate = new Date();
  const currentMonth = currentDate.getFullYear();
  for (let i = 0; i < recordsCopy.length; i++) {
    if (currentMonth === new Date(recordsCopy[i].date).getFullYear()) {
      minutesSpentMonth += recordsCopy[i].minutes;
    }
  }
  return Math.floor(minutesSpentMonth / 60).toFixed(0);
}

export const getTotalMinutes = (records: Flowmodoro[]) => {
  let totalMinutes = 0;
  for (let i = 0; i < records.length; i++) {
    totalMinutes += records[i].minutes;
  }
  return totalMinutes;
};

export const createDateStr = (date : Date) => `${date.getMonth()}/${date.getDate()}/${date.getFullYear()}`;

export const didWorkOnDate = (recordDate : Date, currentDate : Date): boolean => {
  if (
    recordDate.getMonth() === currentDate.getMonth() &&
    recordDate.getDate() === currentDate.getDate() &&
    recordDate.getFullYear() === currentDate.getFullYear()
  ) {
    return true;
  }
  return false;
}

export const getCurrentStreak = (records: Flowmodoro[]) => {
  if (records.length === 0) {
    return 0;
  }
  let recordsCopy = [...records].sort().reverse();
  const map = new Map<string, string>();
  const todaysDate = new Date();
  let streak = 0;
  
  if (
    didWorkOnDate(new Date(recordsCopy[0].date), todaysDate)
  ) {
    streak = 1;
    const todayDateStr = createDateStr(todaysDate);
    recordsCopy = recordsCopy.filter((record) => {
      const recordDate = new Date(record.date);
      const recordDateStr = createDateStr(recordDate);
      return todayDateStr !== recordDateStr;
    })

  }
  
  const currentDate = new Date();
  currentDate.setDate(todaysDate.getDate() - 1);
  for (let i = 0; i < recordsCopy.length; i++) {
    const date = new Date(recordsCopy[i].date);
    const dateStr = createDateStr(date);
    if (map.has(dateStr)) {
      continue;
    }
    if (
      didWorkOnDate(date, currentDate)
    ) {
      streak += 1;
      currentDate.setDate(currentDate.getDate() - 1);
      map.set(dateStr, dateStr);
    } else {
      break;
    }
  }
  return streak;
};

export const getLongestStreak = (records: Flowmodoro[]) => {
  const recordsCopy = [...records].sort().reverse();
  if (recordsCopy.length === 0) {
    return 0;
  }
  let longestStreak = 0;
  let currentStreak = 0;
  let currentDate = new Date(recordsCopy[0].date);
  const map = new Map<string, string>();
  for (const record of recordsCopy) {
    const recordDate = new Date(record.date);
    const recordDateStr = createDateStr(recordDate);
    
    if (map.has(recordDateStr)) {
      //we've already processed this, move to the next one
      continue;
    }
    
    map.set(recordDateStr, recordDateStr);
    if (currentStreak === 0) {
      currentDate = new Date(record.date);
    }
    if (
      didWorkOnDate(recordDate, currentDate)
    ) {
      currentStreak += 1;
      if (currentStreak > longestStreak) {
        longestStreak = currentStreak;
      }
    } else {
      currentStreak = 0;
    }
    currentDate.setDate(currentDate.getDate() - 1);
  }
  return longestStreak;
};
const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const mapFlowmodoroTotals = (records: Flowmodoro[]) => {
  const initialData = daysOfWeek.map((dayOfWeek) => ({
    dayOfWeek,
    minutes: 0,
    total: 0,
  }));

  for (let i = 0; i < records.length; i++) {
    const dayOfWeek = new Date(records[i].date).getDay();
    initialData[dayOfWeek] = {
      ...initialData[dayOfWeek],
      minutes: initialData[dayOfWeek].minutes + records[i].minutes,
      total: initialData[dayOfWeek].total + 1,
    };
  }
  return initialData;
};
