import React from "react";
import {
  Heading,
  Stat,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import { Flowmodoro } from "../../../types/types";
import { getWeekFlowmodoros } from "../utils/statWeekUtils";
import { getTotalMinutes, mapFlowmodoroTotals } from "../utils/statsUtils";
import { ResponsiveBar } from "@nivo/bar";

interface WeekTabProps {
  records: Flowmodoro[];
}

const WeekTab = ({ records }: WeekTabProps) => {
  const weekRecords = getWeekFlowmodoros(records);
  const totalMinutes = getTotalMinutes(weekRecords);
  const days = Math.floor(totalMinutes / (60 * 24)).toFixed(0);
  const leftoverMinutes = totalMinutes % (60 * 24);
  const hours = Math.floor(leftoverMinutes / 60).toFixed(0);
  const minutes = Math.floor(leftoverMinutes % 60).toFixed(0);
  const flowmodoroTotals = mapFlowmodoroTotals(weekRecords);
  return (
    <div>
      <Heading>Time</Heading>
      <StatGroup>
            <Stat>
              <StatNumber>{days}</StatNumber>
              <StatHelpText>Days</StatHelpText>
            </Stat>
            <Stat>
              <StatNumber>{hours}</StatNumber>
              <StatHelpText>Hours</StatHelpText>
            </Stat>
            <Stat>
              <StatNumber>{minutes}</StatNumber>
              <StatHelpText>Minutes</StatHelpText>
            </Stat>
          </StatGroup>
      <div
        style={{
          height: "30vh",
        }}
      >
        <ResponsiveBar
          colors={['teal']}
          data={flowmodoroTotals}
          indexBy={"dayOfWeek"}
          labelTextColor={"white"}
          keys={["minutes"]}
          padding={0.3}
          role="application"
          margin={{ bottom: 50, left: 30, top: 20 }}
          ariaLabel="Nivo bar chart demo"
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: 'middle',
            legendOffset: -40,
            truncateTickAt: 0
        }}
        />
      </div>
      <Heading>Quantity</Heading>
      <Stat>
        <StatLabel>Completed</StatLabel>
        <StatNumber>{weekRecords.length}</StatNumber>
        <StatHelpText>Flowmodoros</StatHelpText>
      </Stat>

      <div
        style={{
          height: "40vh",
        }}
      >
        <ResponsiveBar
          data={flowmodoroTotals}
          colors={['teal']}
          labelTextColor={"white"}
          indexBy={"dayOfWeek"}
          keys={["total"]}
          padding={0.3}
          margin={{ bottom: 50, top: 50, left: 30 }}
          role="application"
        />
      </div>
    </div>
  );
};

export default WeekTab;
