import {
  Button,
  Heading,
  Input,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Text,
  VStack,
  Stack,
  Flex,
  Spacer,
  ModalCloseButton,
  Textarea,
  Center,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { addFlowmodoroRecord } from "../../api/flowmodoroRecords";
import { getCurrentTime } from "../../utils/time";
import { Time } from "./Timer";
import { useNavigate } from "react-router-dom";
import { ButtonGroup } from "@aws-amplify/ui-react";
import AnimatedCheckmark from "./AnimatedCheckmark";
import useSound from "use-sound";

interface EndSessionScreenProps {
  time: Time;
  isOpen: boolean;
  onClose: Function;
}

const EndSessionDrawer = ({ time, isOpen, onClose }: EndSessionScreenProps) => {
  const [description, setDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const navigate = useNavigate();
  const [play] = useSound(
    `${process.env.PUBLIC_URL}/done.mp3`
  );

  useEffect(() => {
    if (hasSubmitted) {
      play();
      const timeoutId = setTimeout(() => {
        navigate("/blocks");
      }, 3000);
  
      return () => clearTimeout(timeoutId);
      
    }
    
  }, [hasSubmitted])
  return (
    <Drawer
      size="full"
      isOpen={isOpen}
      initialFocusRef={undefined}
      onClose={() => {
        if (!isSubmitting) {
          setDescription("");
          onClose();
        }
      }}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>End Flowmodoro</DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody
          textAlign={"center"}>

          {hasSubmitted ? 
          <Center
          height={"70vh"}
        >
            <div>
            <AnimatedCheckmark />
            <br />
            <Text fontSize="lg">Flowmodoro Completed!</Text>
            </div>

          </Center>
          :
          <VStack
            spacing={4}
            style={{
              textAlign: "center",
            }}
          >
            <Text>You've completed</Text>

            <Heading>{time.totalSessionTime}</Heading>

            <Text mb="3">Minutes this Flowmodoro</Text>
            

            <Text fontSize="sm">Description {description.length}/100</Text>
            <Textarea
              resize="none"
              placeholder="Description (optional)"
              aria-label="description"
              value={description}
              onChange={(e) => {
                if (e.target.value.length <= 100) {
                  setDescription(e.target.value);
                }
              }}
            />
            <div>
            <Button 
            onClick={() => {
              onClose();
            }}
          mr="3">
            Cancel
          </Button>
          <Button
            colorScheme="teal"
            isLoading={isSubmitting}
            onClick={() => {
              setIsSubmitting(true);
              addFlowmodoroRecord({
                date: getCurrentTime(),
                scheduleName: time.name,
                minutes: time.totalSessionTime,
                description,
              }).then(() => {
                sessionStorage.removeItem(time.name);
                setHasSubmitted(true);
              });
            }}
          >
            Submit
          </Button>
            </div>
          </VStack>}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default EndSessionDrawer;
