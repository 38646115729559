import {
  Card,
  CardBody,
  Center,
  Heading,
  CardHeader,
  Stack,
} from "@chakra-ui/react";
import React from "react";
import { Navbar } from "../../components/Navbar";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { blogs } from "./blogs";

const Blog = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        marginTop: "10vh",
        textAlign: "center",
      }}
    >
      <Navbar />
      <Helmet>
        <title>Blog | Flowmodoro</title>
        <meta name="description" content="The Flowmodoro Blog" />
      </Helmet>
      <div>
        <Heading>Blog</Heading>
        <br />
        <Center>
          <Stack
            style={{
              width: "80vw",
            }}
          >
            {blogs.map((blog) => (
              <Card
                style={{
                  cursor: "pointer",
                }}
                onClick={() => navigate(blog.urlPath)}
              >
                <CardHeader>
                  <Heading size="sm">{blog.helmetTitle}</Heading>
                </CardHeader>
                <CardBody>
                  <div>{blog.helmetDescription}</div>
                </CardBody>
              </Card>
            ))}
          </Stack>
        </Center>
      </div>
    </div>
  );
};

export default Blog;
